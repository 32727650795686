<template>
  <div class="constructor--image-impl constructor-impl">
    <v-img :src="node.getValue()" :width="`${Number.parseFloat(getWidth())}%`" :style="getMargin()" contain/>
  </div>
</template>

<script>
import WorkareaNode from "../defs/WorkareaNode";

export default {
  name: "ImageToolWorkArea",
  props: {
    node: {
      type: WorkareaNode,
      required: true
    }
  },
  methods: {
    getMargin() {
      let margin = this.node.getStyle('margin');
      if (!margin) return '';
      return `margin: ${margin};`;
    },
    getWidth() {
      let w = this.node.getStyle('width');
      if (w) {
        return w;
      }
      return 100;
    }
  },
  created() {
    this.node.setOnUpdate(() => this.$forceUpdate());
  },
}
</script>

<style scoped>
.constructor--image-impl {
  width: 100% !important;
  margin: inherit !important;
}
</style>