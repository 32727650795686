<template>
  <div class="footer--time__c">
    <div class="footer--date">
      {{ date }}
    </div>
    <v-spacer/>
    <div class="footer--time">
      {{ time }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Time",
  computed: {
    time: {
      get() {
        return this.$dateFns.format(this.refTime, 'HH:mm:ss');
      }
    },
    date: {
      get() {
        return this.$dateFns.format(this.refTime, 'dd.MM.yy');
      }
    }
  },
  data: () => ({
    refTime: Date.now(),
    interval: null
  }),
  methods: {
    updateTime() {
      this.refTime = Date.now();
    }
  },
  created() {
    this.interval = setInterval(this.updateTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;
  }
}
</script>

<style scoped>
.footer--time__c {
  font-size: 4.8vh;
  font-weight: bold;
  margin: .2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  letter-spacing: .04em;
  width: 100%;
}
</style>