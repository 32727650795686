<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>{{ content }}</v-card-text>
    <v-card-actions>
      <div class="error__actions">
        <v-btn raised color="primary" v-if="fatal" @click="reloadPage">Перезагрузить страницу</v-btn>
        <v-btn raised color="primary" v-else @click="clearError">ОК</v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Error",
  props: {
    title: {
      type: String,
      required: false,
      default: 'Ошибка',
    },
    content: {
      type: String,
      required: true
    },
    fatal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    clearError() {
      return this.$store.commit('clearError');
    },
    reloadPage() {
      location.reload()
    }
  }
}
</script>

<style scoped>
  .error__actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
</style>