<template>
  <div class="heading--tool page-editor--tool">
    <div class="tool--icon">
      <v-icon color="secondary">mdi-page-layout-header</v-icon>
    </div>
    <div class="tool--name secondary--text">
      Заголовок
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadingTool"
}
</script>

<style scoped>
</style>