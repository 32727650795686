import axios from '@/plugins/axios'
import {apiEndpoint, weatherIcons} from '@/common'

export default {
  namespaced: true,
  state: {
    temp: 0,
    temp_feels_like: 0,
    description: '',
    humidity: 0,
    icon: ''
  },
  actions: {
    async getWeather(ctx) {
      console.log('Updating weather...');
      let res = await axios.get(apiEndpoint.weather);
      if (res.status !== 200) {
        console.error(res.data);
        return;
      }
      ctx.commit('setWeather', res.data);
    }
  },
  mutations: {
    setWeather(state, weather) {
      console.log(weather);
      let w = weather.weather[0];
      state.temp = parseInt(weather.main.temp);
      state.temp_feels_like = weather.main.feels_like;
      state.description = w.description;
      state.humidity = weather.main.humidity;
      state.icon = weatherIcons[w.icon.slice(0, -1)];
    }
  }
}