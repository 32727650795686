<template>
    <v-card-text>
      <v-text-field
          v-model.number="layout"
          hide-details
          type="number"
          single-line
          autofocus
          :rules="[validFn]"
          label="Количество колонок"
      />
    </v-card-text>
</template>

<script>
import {EventBus, maxColumnsInLayout} from "../../../../common";

export default {
  name: "LayoutSelectorPhase1",
  props: {
    validFn: {
      type: Function,
      required: true,
    }
  },
  data: () => ({
    layout: 0,
  }),
  methods: {
    validate() {
      return !(typeof this.layout === "number" && this.layout <= maxColumnsInLayout && this.layout >= 1) ?
          `Введите число от 1 до ${maxColumnsInLayout}` :
          true;
    },
  },
  watch: {
    layout(val) {
      this.$emit('change', val);
    }
  },
  created() {
    EventBus.$on('PageConstructorResetSelectLayout', () => this.layout = 0);
  }
}
</script>

<style scoped>

</style>