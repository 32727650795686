<!--suppress ALL -->
<template>
  <div class="page-list">
    <v-skeleton-loader v-if="isLoading" type="list-item@4"/>
    <template v-else>
      <v-dialog v-model="newPageShow" max-width="600px">
        <v-card>
          <v-card-title>Создать страницу</v-card-title>
          <v-card-text>
            <v-text-field label="Имя страницы" v-model="newPage.name"/>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="createPage">Создать</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div>
        <v-btn @click="() => {this.newPageShow = true;}">Создать страницу</v-btn>
        <v-btn @click="refresh" :loading="refreshing">Обновить</v-btn>
      </div>
      <div v-if="!pages.length">
        <span>Страниц для отображения не найдено!</span>
      </div>
      <v-list v-else>
        <v-subheader>Страницы</v-subheader>
        <v-list-item v-for="page in pages" :key="`page-${page.id}`" :class="{'accent': page.selected}">
          <v-list-item-action>
            <v-checkbox v-model="page.selected"/>
          </v-list-item-action>
          <v-list-item-content>{{ page.name }}</v-list-item-content>
          <v-list-item-action>
            <v-btn icon color="primary" @click="() => editPage(page)"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn icon color="error" :loading="loadInstances[`page-delete-${page.id}`]" @click="del(page)"><v-icon>mdi-close</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </div>
</template>
<script>
import {Load, EventBus, apiEndpoint} from "../../../common";
import axios from '../../../plugins/axios'

export default {
  name: "pageList",
  computed: {
    isLoading: {
      get() {
        return this.loadInstances.root.isLoading;
      }
    },
    pages: {
      get() {
        return this.$store.state.pageList.pages;
      }
    },
    dialog: {
      get() {
        return this.$store.state.dialog;
      }
    },
    dialogAnswer: {
      get() {
        return this.$store.state.dialog.answer;
      }
    }
  },
  data: () => ({
    loadInstances: {root: new Load()},
    newPageShow: false,
    newPage: {
      name: ''
    },
    refreshing: false
  }),
  methods: {
    refresh() {
      this.refreshing = true;
      axios.get(apiEndpoint.refresh).then(() => {
        this.refreshing = false;
      });
    },
    editPage({id, name}) {
      axios.get(`${apiEndpoint.pageList}/${id}`).then(res => {
        let data = res.data;
        if (typeof res.data == 'object') {
          data = JSON.stringify(res.data);
        }
        this.$store.commit('pageList/setCurPage', {id, name, template: data});
        this.$router.push('/admin/constructor');
      })
    },
    del({id, name}) {
      if(this.dialog.name) {
        console.error(`Dialog (${this.dialog.name}) already opened. I can't open another one at the same time.`);
        this.$store.commit('showError', {
          title: "Окно уже открыто",
          content: "Невозможно открыть ещё одно диалоговое окно, т.к. одно уже открыто!",
          fatal: false
        });
        return;
      }
      EventBus.$once('deletePageAnswer', (answer) => {if (answer) this.delete(id)});
      this.$store.commit('showDialog', {name: 'DeletePageDialog', data: name});
    },
    delete(id) {
      this.$store.commit('clearDialog');
      let load = new Load();
      this.loadInstances[`page-delete-${id}`] = load;
      this.$store.dispatch('pageList/deletePage', {id, load});
    },
    createPage(){
      this.$store.commit('pageList/setCurPage', {name: this.newPage.name});
      this.$router.push('/admin/constructor');
    }
  },
  created() {
    this.$store.dispatch('pageList/getPages', {load: this.loadInstances.root});
  },
  watch: {
    dialogAnswer(val) {
      if (this.dialog.name === 'DeletePageDialog' && (val === true || val === false)) {
        this.$emit('deletePageAnswer', val);
      }
    }
  }
}
</script>

<style scoped>

.page-list {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>