<template>
  <v-app>
    <v-dialog :value="error.show" max-width="400" persistent>
      <error :title="error.title" :content="error.message" :fatal="error.fatal"></error>
    </v-dialog>
    <v-dialog :value="dialog.name" max-width="400" persistent>
      <component :is="dialog.name"/>
    </v-dialog>
    <template v-if="!isLoading">
      <router-view/>
    </template>
  </v-app>
</template>
<script>
import Error from "./components/Error";
import {Load, mainDomain} from "./common";
import registerTools from "./components/Admin/PageEditor/Tools";
import registerWorkarea from "./components/Admin/PageEditor/ToolsImpl";
import registerProperties from "./components/Admin/PageEditor/ToolProperties";
import Vue from "vue";
import WorkareaPhantom from "./components/Admin/PageEditor/WorkareaPhantom";

export default {
  name: 'App',
  components: {
    Error,
  },

  computed: {
    showTopbar: {
      get () {
        return this.$store.state.user.id !== -1
      }
    },
    error: {
      get() {
        return this.$store.state.error;
      }
    },
    dialog: {
      get() {
        return this.$store.state.dialog;
      }
    },
    isLoading: {
      get() {
        return this.loadInstance.isLoading
      }
    }
  },
  methods: {
    async loadUser() {
      await this.$store.dispatch('user/getUser');
      let user = this.$store.state.user
      if (window.location.pathname !== '/' && (!user || user.role !== 'Director' && (user.flags & 0x100) !== 0x100)) {
        console.log('Tried to access protected path wo auth.')
        window.location.replace(`${mainDomain}/signin`);
      }
    }
  },
  data() {
    return {
      auth: false,
      loadInstance: Load.createInstance(this.loadUser, 'Failed to fetch user')
    }
  },
  created() {
    this.loadInstance.start();

    registerTools();
    registerWorkarea();
    registerProperties();
    Vue.component("WorkareaPhantom", WorkareaPhantom);
  }

};
</script>
<style>
.app--header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
</style>
<style scoped>
#main {
  max-width: 800px;
  align-self: center;
  margin-top: 64px;
  width: 100%;
}
.content {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}
.page-loading--wrap{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style>
h1 {
  text-align: center;
}
.display-flex {
  display: flex;
}

.vertical--line {
  height: 100%;
  width: 1px;
  margin: 0 10px 0 10px;
}

.w-100 {
  width: 100%;
}
html {
  overflow-y: auto !important;
}
</style>