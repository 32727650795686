import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from "../views/Main";
import Home from "../views/admin/Home";
import PageConstructor from "../views/admin/PageConstructor";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Main
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Home
  },
  {
    path: '/admin/constructor',
    name: 'Builder',
    component: PageConstructor
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
