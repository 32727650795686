<template>
  <div class="heading--properties tool--properties">

    <v-text-field hide-details outlined dense label="Видео (YouTube)" v-model="props.value"/>

    <div class="d-flex justify-center">
      <v-btn-toggle v-model="alignment">
        <v-btn>
          <v-icon>mdi-format-align-left</v-icon>
        </v-btn>

        <v-btn>
          <v-icon>mdi-format-align-center</v-icon>
        </v-btn>

        <v-btn>
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>

    <v-text-field hide-details outlined dense label="Ширина" v-model.number="props.width" type="number" :min="0" :max="100"/>
    <v-subheader>Рамка</v-subheader>
    <v-color-picker v-model="props.border.color" hide-inputs/>
    <v-text-field hide-details outlined dense label="Размер" v-model.number="props.border.sides" type="number" :max="5" :min="0"/>
    <v-spacer/>
    <v-btn color="primary" @click="() => node.hideSkeleton()">{{ node.isSkelHidden() ? 'Показать скелет' : 'Спрятать скелет'}}</v-btn>
  </div>
</template>

<script>
import WorkareaNode from "../defs/WorkareaNode";
import {compressImage} from "../../../../common";

export default {
  name: "VideoProperties",
  props: {
    node: {
      type: WorkareaNode,
      required: true
    }
  },
  data: () => ({
    alignment: 0,
    props: {
      value: 'https://www.youtube.com/watch?v=XY8xoG1EXcA',
      border: {
        type: 'solid',
        color: '#000000',
        sides: 0
      },
      width: 100,
      margin: '0'
    },
    loading: false,
    updateTimestamp: 0,
    updateTimeout: 0,
    update: false
  }),
  methods: {
    submitImg(f) {
      if (f == null) return;
      this.loading = true;
      let reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => {
        compressImage(reader.result).then(url => {
          this.props.value = url
          this.loading = false;
        });
      }
    }
  },
  created() {
    this.props.value = this.node.getValue() || this.props.value;
    let width = this.node.getStyle('width')
    this.props.width = width ? Number.parseFloat(width) : 100;

    let margin = this.node.getStyle('margin');
    if (margin === '0') {
      this.alignment = 0;
    } else if (margin === '0 auto') {
      this.alignment = 1;
    } else if (margin === '0 0 0 auto') {
      this.alignment = 2;
    }
    let border = this.node.getStyle("border");
    if (border && typeof border != 'string' && border.length >= 3) {
      this.props.border.type = border[0];
      this.props.border.color = border[1];
      this.props.border.sides = Number.parseInt(border[2]);
    }
  },
  watch: {
    props: {
      handler() {
        let upd = this.updateTimestamp + 1000 < Date.now();

        let border = this.props.border;
        this.node.addStyle("border", [border.type, border.color, `${border.sides}px`])
        this.node.addStyle('width', `${this.props.width}%`);
        this.node.addStyle('margin', this.props.margin);

        this.node.setValue(this.props.value);

        if (upd) {
          this.updateTimestamp = Date.now();
          this.updateTimeout = setTimeout(() => {
            if (this.update) this.node.forceUpdate(false);
          }, 1000);
          this.node.forceUpdate(false);
        } else if (this.updateTimeout && !this.update) {
          this.update = true;
        }
      },
      deep: true
    },
    alignment() {
      if (this.alignment === 0) {
        this.props.margin = '0';
      } else if (this.alignment === 1) {
        this.props.margin = '0 auto';
      } else if (this.alignment === 2) {
        this.props.margin = '0 0 0 auto';
      }
    }
  }
}
</script>

<style scoped>

</style>