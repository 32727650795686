<template>
  <div>
    <v-skeleton-loader v-if="isLoading" type="article, actions"/>
    <v-card v-else>
      <v-card-title>Удалить?</v-card-title>
      <v-card-text>
        <span>Вы действительно хотите удалить страницу "{{ name }}"?</span>
        <span class="font-weight-bold error--text">Отменить это действие невозможно!</span>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="primary" @click="cancel">Отмена</v-btn>
        <v-spacer/>
        <v-btn color="error" @click="del">Удалить!</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {EventBus} from "../../../common";
export default {
  name: "DeletePageDialog",
  computed: {
    name: {
      get() {
        return this.$store.state.dialog.data;
      }
    }
  },
  methods: {
    cancel() {
      this.$store.commit('setDialogAnswer', false);
      EventBus.$emit('deletePageAnswer', false);
      this.close();
    },
    del() {
      this.$store.commit('setDialogAnswer', true);
      EventBus.$emit('deletePageAnswer', true);
      this.close();
    },
    close() {
      this.$store.commit('hideDialog');
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>