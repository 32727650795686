import Vue from 'vue';
import Vuex from 'vuex';
import VueYT from 'vue-youtube';

import App from './App.vue';
import vuetify from './plugins/vuetify';
import DateFns from "./plugins/DateFns";
import store from './store';
import router from './router';

import RegisterDialogComponents from './registerDialogComponents';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(DateFns);
Vue.use(VueYT);

RegisterDialogComponents();

new Vue({
  vuetify,
  router,
  render: h => h(App),
  store
}).$mount('#app');
