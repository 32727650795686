<template>
  <div class="text--tool page-editor--tool">
    <div class="tool--icon" >
      <v-icon color="secondary">mdi-card-text</v-icon>
    </div>
    <div class="tool--name secondary--text">
      Текст
    </div>
  </div>
</template>

<script>
export default {
  name: "TextTool"
}
</script>

<style scoped>

</style>