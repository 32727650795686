<template>
  <v-card>
    <v-card-title>Выбор макета</v-card-title>
    <layout-selector-phase1 ref="fstPhase" :valid-fn="validate" v-if="phase === 1" @change="(l) => this.layout = l" />
    <layout-selector-phase2 ref="sndPhase" v-else :layout="layout" />
    <v-card-actions>
      <v-btn raised color="primary" :disabled="!isValid" @click="next">Далее</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import LayoutSelectorPhase1 from "./Phase1";
import LayoutSelectorPhase2 from "./Phase2";
import {EventBus, shallowCopy} from "../../../../common";
export default {
  name: "LayoutSelectorIndex",
  components: {LayoutSelectorPhase2, LayoutSelectorPhase1},
  data: () => ({
    layout: 0,
    phase: 1,
    isValid: false
  }),
  methods: {
    next() {
      if (!this.isValid) return;
      if (this.phase === 1) return this.phase++;
      this.$emit("end", shallowCopy(this.$refs.sndPhase.getPosition()));

      EventBus.$emit('PageConstructorResetSelectLayout');

      this.phase = 1;
      this.layout = 0;
      this.isValid = false;
    },
    validate() {
      let valid = this.$refs.fstPhase ? this.$refs.fstPhase.validate() : false;

      this.isValid = typeof valid === 'string' ? false : valid;

      return valid;
    }
  }
}
</script>

<style scoped>

</style>