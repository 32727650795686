<template>
  <div class="heading--work-area page-editor--work-area constructor-impl">
    <h2>{{ node.getValue() ? node.getValue() : "Заголовок" }}</h2>
  </div>
</template>

<script>
import WorkareaNode from "../defs/WorkareaNode";

export default {
  name: "HeadingWorkArea",
  props: {
    node: {
      type: WorkareaNode,
      required: true
    }
  },
  created() {
    this.node.setOnUpdate(() => this.$forceUpdate());
  }
}
</script>

<style scoped>

</style>