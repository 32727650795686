<template>
  <div class="tool--properties column--properties">
<!--    <v-subheader>Ширина</v-subheader>-->
<!--    <v-text-field label="Ширина" v-model="width_" type="number"/>-->
<!--    -->
    <v-subheader>Рамка</v-subheader>
    <v-color-picker v-model="props.border.color" hide-inputs/>
    <v-text-field hide-details outlined dense label="Размер" v-model.number="props.border.sides" type="number" :max="5" :min="0"/>
    <v-text-field hide-details outlined dense label="Высота" v-model.number="props.height" type="number" :min="20" :max="100" suffix="vh"/>
    <v-subheader>Фон</v-subheader>
    <v-color-picker v-model="props.backgroundColor" hide-inputs/>
    <v-file-input hide-details outlined dense label="Фон-картинка" accept="image/*" @change="addImgBackground" :loading="bgImgLoader"/>
    <v-spacer/>
    <v-btn color="primary" @click="() => node.hideSkeleton()">{{ node.isSkelHidden() ? 'Показать скелет' : 'Спрятать скелет'}}</v-btn>
  </div>
</template>

<script>
import WorkareaNode from "../defs/WorkareaNode";
import {compressImage} from "../../../../common";

export default {
  name: "ColumnProperties",
  props: {
    node: {
      type: WorkareaNode,
      required: true
    }
  },
  data: () => ({
    props: {
      width_: 0,
      height: 20,
      border: {
        type: 'solid',
        color: '#000000',
        sides: 0
      },
      backgroundColor: '#fff',
      backgroundImg: ''
    },
    updateTimestamp: 0,
    updateTimeout: 0,
    update: false,
    bgImgLoader: false,
  }),
  methods: {
    /**
     *
     * @param img {File}
     */
    addImgBackground(img) {
      if (img == null) {
        this.props.backgroundImg = "";
        return;
      }
      this.bgImgLoader = true;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = () => {
        compressImage(reader.result).then(a => {
          this.props.backgroundImg = `url(${a})`;
          this.bgImgLoader = false;
        });
      }
    }
  },
  created() {
    this.props.width_ = this.node.getWidth();
    let border = this.node.getStyle("border");
    if (border && typeof border != 'string' && border.length >= 3) {
      this.props.border.type = border[0];
      this.props.border.color = border[1];
      this.props.border.sides = Number.parseInt(border[2]);
    }
    let h = this.node.getStyle("max-height");
    if (h) {
      this.props.height = Number.parseFloat(h);
    }
    let backgroundColor = this.node.getStyle("background-color");
    if (backgroundColor && typeof backgroundColor == 'string') {
      this.props.backgroundColor = backgroundColor;
    }
    let backgroundImg = this.node.getStyle("background-image");
    if(backgroundImg && typeof backgroundImg == 'string') {
      this.props.backgroundImg = backgroundImg;
    }
  },
  watch: {
    props: {
      handler() {
        let upd = this.updateTimestamp + 1000 < Date.now();
        this.node.updateProps({width: this.props.width_}, upd);

        let border = this.props.border;
        this.node.addStyle("border", [border.type, border.color, `${border.sides}px`])
        this.node.addStyle("background-color", this.props.backgroundColor);
        if (this.props.backgroundImg) {
          this.node.addStyle("background", `${this.props.backgroundImg} 50% 50% no-repeat`);
          if (!this.node.getStyle('background-size'))
            this.node.addStyle("background-size", 'cover');
        }
        this.node.addStyle("height", `${this.props.height}vh`);

        if (upd) {
          this.updateTimestamp = Date.now();
          this.updateTimeout = setTimeout(() => {
            if (this.update) this.node.forceUpdate(false);
          }, 1000);
          this.node.forceUpdate(false);
        } else if (this.updateTimeout && !this.update) {
          this.update = true;
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>