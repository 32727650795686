<template>
  <div class="carousel--slide">
<!--    <template v-if="loading">-->
<!--      <v-skeleton-loader type="image, article"/>-->
<!--    </template>-->
<!--    <template v-else-if="html.length > 0">-->
<!--      <template v-html="html"/>-->
<!--    </template>-->
    <template>
      <WorkareaNode :node="rootNode"/>
    </template>
  </div>
</template>

<script>
import {pageConstructorTypes} from "../../../common";
import WorkareaNode from "../../Admin/PageEditor/defs/WorkareaNode";
import DropDirection from "../../Admin/PageEditor/defs/DropDirection";
import WorkareaNodeC from "../../Admin/PageEditor/WorkareaNode";

const scriptsRegex = /<script[^]*>(?<code>[^]*)<\/script>/gi;
const scriptSrcRegex = /<script[^]src="(?<src>[^"]+)"><\/script>/gi;

export default {
  name: "slide",
  components: {WorkareaNode: WorkareaNodeC},
  props: {
    // Url of the template of this slide
    template: {
      type: String,
      required: true
    },

    wasMounted: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: ()=>({
    rootNode: null,
    scripts: ''
  }),
  methods: {
    /**
     *
     * @param node {WorkareaNode}
     */
    evalScripts(node) {
      if (!node.hasChildren()) return;
      for (let i = 0; i < node.childrenSize(); ++i) {
        let child = node.getChild(i);
        if(!child) continue;

        if (child.getType() === pageConstructorTypes.HTML) {
          let value = child.getValue();
          if (typeof value != 'string') continue;

          let matches = [...value.matchAll(scriptsRegex)];
          if (matches.length === 0) continue;

          if (!this.wasMounted) {
            let srcMatches = [...value.matchAll(scriptSrcRegex)];
            if (srcMatches.length > 0) {
              console.log("Script sources found: ", srcMatches);
              srcMatches.forEach(m => {
                let s = document.createElement("script");
                s.src = m[1];
                document.body.appendChild(s);
                console.log(m[1], "inserted");
              });
            }
          }

          matches.forEach(m => {
            setTimeout(() => eval(m[1]), 1500);
          });

          // let match = it.next();
          // while(!match.done) {
          //   if(match.value.toString().startsWith('<')) {
          //     let script = document.createElement('div');
          //     script.innerHTML = match.value;
          //     let d = document.body.appendChild(script);
          //     for (let i = 0; i < d.children.length; ++i) {
          //       let item = d.children.item(i)
          //       if(item.tagName.toLowerCase() === 'script') {
          //         eval(item.textContent);
          //         item.remove();
          //       }
          //     }
          //   }
          //
          //   match = it.next();
          // }
        } else {
          this.evalScripts(child);
        }
      }
    }
  },
  created() {
    this.rootNode = new WorkareaNode(pageConstructorTypes.Column, {width: 100}, DropDirection.NONE);
    this.rootNode.deserialize(JSON.parse(this.template));
    this.rootNode.remapChildren(0, false);
    const map = child => {
      if (child.childrenSize() > 0) {
        child.remapChildren(0, false);
        child.getChildren().forEach(map);
      }
    }
    this.rootNode.getChildren().forEach(map);
    if (!this.rootNode.isSkelHidden()) this.rootNode.hideSkeleton();
    this.rootNode.forceUpdate(true);
  },
  mounted() {
    this.evalScripts(this.rootNode);
    this.$emit('mounted');
  }
}
</script>




<style scoped>

</style>