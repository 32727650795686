<template>
  <div class="main--wrap">
    <top-bar class="app--header d-flex"/>
    <v-main id="main">
      <div class="content">
<!--        <template v-if="loadInstance.isLoading">-->
<!--          <v-skeleton-loader type="image, article"/>-->
<!--        </template>-->
        <carousel v-if="show" :slides="slides"/>
      </div>
    </v-main>
    <media-footer/>
  </div>
</template>

<script>
import topBar from '../components/TopBar'
import footer from '../components/Footer'
import carousel from '../components/Common/Carousel'
import axios from '../plugins/axios'
import {apiEndpoint} from "../common";

export default {
  name: "Main",
  components: {
    'top-bar': topBar,
    'media-footer': footer,
    carousel
  },
  data: function() {
    return {
      show: false,
      slides: [],
      //loadInstance: Load.createInstance(this.loadTemplate, 'Failed to load slide templates list.')
    }
  },
  methods: {
    // async loadTemplate() {
    //   let res = await axios.get(apiEndpoint.templateList);
    //   if(res.status !== 200) throw `Status code: ${res.status}\nResponse: ${res.data}`;
    //   return res;
    // },
    // loaded(res) {
    //   this.slides = res.data.map(t => t.template);
    //   this.$nextTick(() => {
    //     this.show = true;
    //   });
    // }
    loadTemplates() {
      axios.get(apiEndpoint.templateList).then(res => {
        if (res.status !== 200) return console.error(res.data);
        this.slides = res.data.map(t => t.template);
        this.show = true;
      });
    },
    openWebsocket() {
      let wss = new WebSocket(apiEndpoint.websocket);
      wss.onclose = () => {
        setTimeout(() => {
          this.openWebsocket();
        }, 5000);
      };
      wss.onmessage = (e) => {
        if (e.data == 'refresh') document.location.reload();
      }
      if (wss.readyState === wss.CLOSED) {
        console.log("Couldn't open websocket connection");
      }
    }
  },
  created() {
    this.loadTemplates();
    document.querySelector('html').style.overflowY = 'hidden';
  },
  mounted() {
    this.openWebsocket();
  },
  destroyed() {
    document.querySelector('html').style.overflowY = '';
  }
}
</script>

<style scoped>
.main--wrap {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

</style>