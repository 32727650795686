import { parse, intervalToDuration, isWithinInterval, format } from "date-fns";
import {padDate} from "@/common";

const DateFns = {
  isWithinInterval,
  format
};

// Time must be formatted as HH:mm:ss or HH:mm
DateFns.parseTime = (time = "") => parse(time, time.length === 5 ? 'HH:mm' : 'HH:mm:ss', new Date());
DateFns.intervalToFormattedDuration = (interval = {start: new Date(), end: new Date()}) => {
  let d = intervalToDuration(interval);
  let a = '';
  if (d.years > 0) {
    a += `${padDate(d.years)}:`;
  }
  if (d.months > 0) {
    a += `${padDate(d.months)}:`;
  }
  if (d.days > 0) {
    a += `${padDate(d.days)}:`;
  }
  if (d.hours > 0) {
    a += `${padDate(d.hours)}:`;
  }

  a += `${padDate(d.minutes)}:${padDate(d.seconds)}`;

  return a;
}

export default DateFns