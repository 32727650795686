//import axios from '@/plugins/axios';
import dateFns from '../../plugins/DateFns/date-fns'

export default {
  namespaced: true,
  state: {
    schedule: [],
    // Current Lesson.
    // Lessons numeration starts from 1.
    // If current is 0, there is no lessons now.
    // If current is negative, now is break between ABS(current) and ABS(current)+1 lessons;
    current: 0,
  },
  actions: {
    async updateAlarmSchedule() {
      // Update asynchronously;
      // commit setSchedule;
      // commit findCurrent;
    }
  },
  mutations: {
    setSchedule(state, schedule) {
      state.schedule = schedule;
    },
    findCurrent(state) {
      let now = Date.now();
      state.schedule.some((e,i) => {
        if (dateFns.isWithinInterval(now, e)) {
          state.current = e.id;
          return;
        }
        if (dateFns.isWithinInterval(now, {start: dateFns.parseTime(e.end), end: dateFns.parseTime(state.schedule[i + 1].start)})) {
          state.current = -e.id;
        }
      });
    }
  },
  getters: {
    getTimeToNextLesson(state) {
      if (state.current === 0) {
        return dateFns.intervalToFormattedDuration({start: new Date(), end: dateFns.parseTime(state.schedule[0].start)});
      }
      if (state.current < 0) {
        return dateFns.intervalToFormattedDuration({start: new Date(), end: state.schedule[Math.abs(state.current)].start});
      }
      return dateFns.intervalToFormattedDuration({start: new Date(), end: state.schedule[state.current-1].end});
    }
  }
}
