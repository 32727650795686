<template>
  <v-container class="top-bar--media d-flex primary elevation-4">
    <div class="top-bar--media__l-logo d-flex">
      <img class="l-logo" src="@/assets/liceylogo.png" alt="Логотип Лицей №9"/>
      <div class="top-bar--media__ d-flex secondary--text">
        <div class="top-bar--media__name">КОГОБУ Лицей №9 г. Слободского</div>
        <div class="top-bar--media__watchword">«Если быть, то быть лучшим»</div>
      </div>
    </div>
    <div class="top-bar--media__weather">
      <weather/>
    </div>
  </v-container>
</template>

<script>
import Weather from "@/components/TopBar/Weather";
export default {
  name: "Bar",
  components: {
    Weather
  }
}
</script>

<style scoped>

.top-bar--media {
  height: 100vh;
  margin: 0;
  justify-content: space-between;
  max-height: 10%;
  max-width: 100vw;
  position: relative;
}
.top-bar--media__ {
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
}
.l-logo {
  max-width: 30vw;
  height: 7.5vh;
}
.top-bar--media__name {
  font-size: 2.4vh;
}
.top-bar--media__watchword {
  font-size: 1.8vh;
  font-style: italic;
  line-height: 1;
}
</style>