<template>
  <div class="heading--properties tool--properties">
    <v-textarea hide-details outlined dense label="Текст" v-model="props.value"/>
    <v-subheader>Типографика</v-subheader>
    <v-text-field hide-details dense outlined type="number" label="Размер шрифта" v-model="props.fontSize" suffix="vh"/>
    <div class="d-flex justify-center">
      <v-btn-toggle v-model="alignment">
        <v-btn>
          <v-icon>mdi-format-align-left</v-icon>
        </v-btn>

        <v-btn>
          <v-icon>mdi-format-align-center</v-icon>
        </v-btn>

        <v-btn>
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>

        <v-btn>
          <v-icon>mdi-format-align-justify</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-color-picker v-model="props.color" hide-inputs/>

    <v-subheader>Рамка</v-subheader>
    <v-color-picker v-model="props.border.color" hide-inputs/>
    <v-text-field hide-details outlined dense label="Размер" v-model.number="props.border.sides" type="number" :max="5" :min="0"/>
    <v-spacer/>
    <v-btn color="primary" @click="() => node.hideSkeleton()">{{ node.isSkelHidden() ? 'Показать скелет' : 'Спрятать скелет'}}</v-btn>
  </div>
</template>

<script>
import WorkareaNode from "../defs/WorkareaNode";

export default {
  name: "TextProperties",
  props: {
    node: {
      type: WorkareaNode,
      required: true
    }
  },
  data: () => ({
    alignment: 0,
    props: {
      value: 'Заголовок',
      border: {
        type: 'solid',
        color: '#000000',
        sides: 0
      },
      textAlign: 'left',
      fontSize: 0,
      color: '#000'
    },
    updateTimestamp: 0,
    updateTimeout: 0,
    update: false
  }),
  created() {
    this.props.value = this.node.getValue();
    this.props.fontSize = Number.parseFloat(this.node.getStyle("font-size") || "3vh");
    this.props.alignment = this.node.getStyle('text-align') || 'left';
    let border = this.node.getStyle("border");
    if (border && typeof border != 'string' && border.length >= 3) {
      this.props.border.type = border[0];
      this.props.border.color = border[1];
      this.props.border.sides = Number.parseInt(border[2]);
    }
    switch (this.props.alignment) {
      case 'left':
        this.alignment = 0;
        break;
      case 'center':
        this.alignment = 1;
        break;
      case 'right':
        this.alignment = 2;
        break;
      case 'justify':
        this.alignment = 3;
        break;
    }
  },
  watch: {
    alignment() {
      switch (this.alignment) {
        case 0:
          this.props.textAlign = 'left';
          break;
        case 1:
          this.props.textAlign = 'center';
          break;
        case 2:
          this.props.textAlign = 'right';
          break;
        case 3:
          this.props.textAlign = 'justify';
          break;
      }
    },
    props: {
      handler() {
        let upd = this.updateTimestamp + 1000 < Date.now();

        let border = this.props.border;
        this.node.addStyle("border", [border.type, border.color, `${border.sides}px`])
        this.node.addStyle("color", this.props.color);
        this.node.addStyle("text-align", this.props.textAlign);
        this.node.addStyle("font-size", `${this.props.fontSize}vh`);

        this.node.setValue(this.props.value);

        if (upd) {
          this.updateTimestamp = Date.now();
          this.updateTimeout = setTimeout(() => {
            if (this.update) this.node.forceUpdate(false);
          }, 1000);
          this.node.forceUpdate(false);
        } else if (this.updateTimeout && !this.update) {
          this.update = true;
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>