<template>
  <div class="page-editor--tool" :draggable="true" @dragstart="dragStart" @dragend="dragEnd">
    <component :is="info.getTool()"/>
  </div>
</template>

<script>
import WorkareaNode from "./defs/WorkareaNode";
import WorkareaToolInfo from "./defs/WorkareaToolInfo";
import DragInfo from "./defs/DragInfo";

export default {
  name: "WorkareaTool",
  props: {
    info: {
      type: WorkareaToolInfo,
      required: true
    },
    rootNode: {
      type: WorkareaNode,
      required: true
    }
  },
  methods: {
    dragStart() {
      this.rootNode.setDragInfo(new DragInfo(this.info.getType(), this.info));
    },
    dragEnd() {
      this.rootNode.clearDragInfo();
      this.rootNode.removePhantoms();
    },
  }
}
</script>

<style>

.page-editor--tool > * {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>