<template>
  <div class="constructor--video-impl constructor-impl">
    <youtube ref="youtube" :video-id="$youtube.getIdFromUrl(node.getValue())" :fit-parent="true" :resize="true" :player-vars="{autoplay: 1}"/>
  </div>
</template>

<script>
import WorkareaNode from "../defs/WorkareaNode";

export default {
  name: "VideoToolWorkArea",
  props: {
    node: {
      type: WorkareaNode,
      required: true
    }
  },
  created() {
    this.node.setOnUpdate(() => this.$forceUpdate());
  },
  beforeDestroy() {

  }
}
</script>

<style scoped>

</style>