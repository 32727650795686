import Vue from 'vue'
export const mainDomain = 'https://onlinemodule.ru';
export const server = document.location.origin; // localhost
export const apiEndpoint = {
  websocket: `wss://${document.location.host}/api/v1/media/websocket`,
  user: `${server}/api/v1/user`,
  templateList: `${server}/api/v1/templateList`,
  pageList: `${server}/api/v1/pages`,
  refresh: `${server}/api/v1/media/refresh`,
  weather: `https://api.openweathermap.org/data/2.5/weather?id=491882&appid=6275dfeb2a8f905ab08ef15962750fbb&lang=ru&units=metric`,
  time: `https://worldtimeapi.org/api/timezone/Europe/Kirov`
};
export let refDate = new Date();
export const weatherIcons = {
  '01': 'mdi-weather-sunny',
  '02': 'mdi-weather-partly-cloudy',
  '03': 'mdi-weather-cloudy',
  '04': 'mdi-weather-partly-rainy',
  '05': 'mdi-weather-rainy',
  '06': 'mdi-weather-pouring',
  '07': 'mdi-weather-lightning',
  '08': 'mdi-weather-snowy-heavy',
  '09': 'mdi-weather-tornado'
};
export const weatherUpdateInterval = 900000;
export const EventBus = new Vue()
export const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
export const addOrdinal = (n, p = {one: '', two: 'а', few: 'а', many: 'ов'} ) => `${p[new Intl.PluralRules('ru').select(n)]}`;
export const urlRegex = /(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?/gi;
export const wrapUrl = str => str.split(' ').map(w=>w.replace(urlRegex, '<a href="$&">$&</a>')).join(' ');
export const isOverflown = (element) => element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
export const generateString = length => Array(length).fill('').map(() => Math.random().toString(36).charAt(2)).join('');
export const generateBoolean = () => Math.random() >= 0.5;
export const loadingAttemptsBeforeInterval = 5; // this is number of attempts without interval.
export const loadingAttemptsInterval = 5000;  // this is interval between loading attempts (ms)
export const loadingAttemptsIntervalGain = 2000; // this is interval between loading attempts time gain (ms) e.g. after each attempt this number of ms is added to interval time
export const maxLoadingAttemptsInterval = 15000 // this is max interval between loading attempts
export const slideChangingInterval = 15000 // this is interval between slide change
export const asyncWait = (ms = 0) => new Promise(resolve => {setTimeout(resolve, ms)})
export const getExt = fileName => fileName.split('.').pop();
export const pluck = (objs, property) => objs.map(obj => obj[property]);
export const unique = arr => [...new Set(arr)];
export const weekAgo = (date = new Date()) => generateDateArr(-7, date);
export const dateToString = (e = new Date()) => e.toISOString().slice(0, 10);
export const dateArrayToString = d => d.map(dateToString);
export const dateConvert = d => `${padDate(d.getDate())}-${padDate(d.getMonth()+1)}-${padDate(d.getFullYear())} ${padDate(d.getHours())}:${padDate(d.getMinutes())}:${padDate(d.getSeconds())}`;
export const padDate = d => `${d}`.padStart(2, '0');
export const compareDates = (f, s) => {
  let a = toDate(f), b = toDate(s);
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
}
// If value positive => f > s; If value is 0 => f == s; If value is negative f < s;
export const compareDatesFull = (f = new Date(), s = new Date()) => f.getTime() - s.getTime();
export const dateStringToISO = d => dateToString(new Date(d));
export const generateDate = (a, b = new Date()) => new Date(b.valueOf() + 1000 * 60 * 60 * 24 * a);
export const yesterday = (date = new Date()) => generateDate(-1, date);
export const tomorrow = (date = new Date()) => generateDate(1, date);
export const generateDateArr = (a,b = new Date()) => {
    let arr = [];
    for(let i = 0; i <= Math.abs(a); ++i) {
        arr[i] = b;
        b = a < 0 ? yesterday(b) : tomorrow(b);
    }
    return arr;
}
export const deepObjectComparing = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);
export const shallowCopy = obj => obj instanceof Array ? [...obj] : {...obj};
export const formatDate = (date) => date.split('-').reverse().join('-');
export const getDateMonth = (date = new Date()) => `${padDate(date.getDate())}.${padDate(date.getMonth()+1)}`
export const getDate = (d = new Date()) => d instanceof Date ? Date.parse(`${dateToString(d)} 0:0:0`) : Date.parse(dateToString(toDate(d)));
export const sum = arr => arr.reduce((a, b) => a + b, 0);
export const isObjEmpty = obj => JSON.stringify(obj) === '{}';
export const getIntersection = (a, b) => [...new Set(a)].filter(v => b.includes(v));
export const toDate = (a = new Date()) => (a instanceof Date) ? a : new Date(a.length < 11 ? `${a} 0:0:0` : a);
export const nl2br = str => str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
export const toMiniRFC3339DateStr = (str = "") => str.slice(0, 10)
export const toMiniRFC3339Date = (d = new Date()) => toMiniRFC3339DateStr(d.toISOString())
export const getTimeFromDate = (d = new Date()) => `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
export const isArrEqual = (a, b) => JSON.stringify(a.sort()) === JSON.stringify(b.sort());
export const pageConstructorTypes = {
  Layout: 'layout',
  Column: 'column',
  Heading: 'heading',
  Image: 'image',
  Video: 'video',
  Text: 'text',
  HTML: 'HTML',
};
export const maxColumnsInLayout = 5;
export const monitorAspectRatio = 16 / 9;
export const fillArray = (arr, val, start = 0, end = 0) => {
  if (start <= end) return []
  for(let i = start; i<end; ++i) {
    arr.push(val)
  }
  return arr;
}
export const compressImage = (base64Image, output = 'url') => new Promise( resolve => {
  let canvas = document.createElement('canvas');
  let img = document.createElement('img');
  img.onload = () => {
    const maxWidth = 1920;
    const maxHeight = 1080;
    let imgHeight = img.height;
    let imgWidth = img.width;
    if(imgWidth > imgHeight) {
      if (imgWidth > maxWidth) {
        imgHeight = Math.round((imgHeight *= maxWidth / imgWidth))
        imgWidth = maxWidth;
      }
    } else {
      if (imgHeight > maxHeight) {
        imgWidth = Math.round((imgWidth *= maxHeight / imgHeight));
        imgHeight = maxHeight;
      }
    }
    canvas.width = imgWidth;
    canvas.height = imgHeight;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, imgWidth, imgHeight)
    switch (output) {
      case "blob":
        canvas.toBlob(resolve, 'image/jpeg', 0.7)
        break
      default:
        resolve(canvas.toDataURL('image/jpeg', 0.7));
        break
    }
  }
  img.src = base64Image;
});

export class Load {
  static createInstance(loadFunc, failHint) {
    return new Load(loadFunc, failHint)
  }

  constructor(loadFunc = (async () => {}), failHint = '') {
    this.attempt = 0;
    this.interval = 0;
    this.isLoading = false;
    this._loadFunc = loadFunc;
    this._failHint = (failHint || 'Failed to load data.').trim();
  }

  setLoadFunc(loadFunc = (async () => {})) {
    this._loadFunc = loadFunc;
  }

  setFailHint(failHint = '') {
    this._failHint = failHint.trim();
  }

  async _end() {
    this.isLoading = false;
  }

  async _load() {
    try {
      this.attempt++;
      let r = await this._loadFunc();
      this.interval = 0;
      this.attempt = 0;
      return r;
    } catch (e) {
      console.error(`${this._failHint} ${e}. Re-trying (${this.attempt})...`);
      if (this.attempt > loadingAttemptsBeforeInterval) {
        console.log(`Interval: ${this.interval}`);
        if (this.interval < maxLoadingAttemptsInterval) {
          this.interval = this.interval > 0 ? this.interval + loadingAttemptsIntervalGain : loadingAttemptsInterval;
          if (this.interval > maxLoadingAttemptsInterval) this.interval = maxLoadingAttemptsInterval;
        }
        await asyncWait(this.interval);
      }
      return await this._load();
    }
  }

  async start() {
    this.isLoading = true;
    return await this._loadingChain();
  }

  // Just a syntax sugar
  async _loadingChain() {
    let r = await this._load();
    await this._end();
    return r;
  }
}

export const isChild = (obj,parentObj) => {
  while (obj != undefined && obj != null && obj.tagName.toUpperCase() != 'BODY'){
    if (obj == parentObj){
      return true;
    }
    obj = obj.parentNode;
  }
  return false;
}

export const typeToPropertiesTemplateName = type => {
  switch (type) {
    case pageConstructorTypes.Layout: {
      return "LayoutProperties";
    }
    case pageConstructorTypes.Text: {
      return "TextProperties";
    }
    case pageConstructorTypes.Heading: {
      return "HeadingProperties";
    }
    case pageConstructorTypes.Video: {
      return "VideoProperties";
    }
    case pageConstructorTypes.Image: {
      return "ImageProperties";
    }
    case pageConstructorTypes.Column: {
      return "ColumnProperties";
    }
    case pageConstructorTypes.HTML: {
      return "HTMLProperties";
    }
    default: {
      return type;
    }
  }
};

export const typeToToolTemplateName = type => {
  switch (type) {
    case pageConstructorTypes.Layout: {
      return "LayoutTool";
    }
    case pageConstructorTypes.Text: {
      return "TextTool";
    }
    case pageConstructorTypes.Heading: {
      return "HeadingTool";
    }
    case pageConstructorTypes.Video: {
      return "VideoTool";
    }
    case pageConstructorTypes.Image: {
      return "ImageTool";
    }
    case pageConstructorTypes.Column: {
      return "ColumnTool";
    }
    case pageConstructorTypes.HTML: {
      return "HTMLTool";
    }
    default: {
      return type;
    }
  }
};

export const typeToWorkareaTemplateName = type => {
  switch (type) {
    case pageConstructorTypes.Layout: {
      return "LayoutWorkArea";
    }
    case pageConstructorTypes.Text: {
      return "TextWorkArea";
    }
    case pageConstructorTypes.Heading: {
      return "HeadingWorkArea";
    }
    case pageConstructorTypes.Video: {
      return "VideoWorkArea";
    }
    case pageConstructorTypes.Image: {
      return "ImageWorkArea";
    }
    case pageConstructorTypes.Column: {
      return "ColumnWorkArea";
    }
    case pageConstructorTypes.HTML: {
      return "HTMLWorkArea";
    }
    default: {
      return type;
    }
  }
}

export class ArgumentTypeMismatchError extends Error {
  static multiple(arr) {
    return new ArgumentTypeMismatchError(arr, true);
  }

  static single(v) {
    return new ArgumentTypeMismatchError(v, false);
  }

  constructor(varProvided, isArr) {
    if (isArr) {
      super(`Argument Type Mismatch Error. Provided: ${varProvided.forEach(v => `${typeof v} (${v.constructor.name}); `)}`);
    } else {
      super(`Argument Type Mismatch Error. Provided: ${typeof varProvided} (${varProvided.constructor.name})`);
    }
  }
}

export class ArgumentOutOfRangeError extends Error {
  static multiple(arr) {
    return new ArgumentOutOfRangeError(arr, true);
  }

  static single(v) {
    return new ArgumentOutOfRangeError(v, false);
  }

  constructor(varProvided, isArr) {
    if (isArr) {
      super(`Argument Out Of Range Error. Provided: ${varProvided.reduce((a, v) => a + `${v}; `)}`);
    } else {
      super(`Argument Out Of Range Error. Provided: ${varProvided}`);
    }
  }
}