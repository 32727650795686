import {ArgumentTypeMismatchError} from "../../../../common";
import WorkareaNode from "./WorkareaNode";
import WorkareaToolInfo from "./WorkareaToolInfo";

export default class DragInfo {
  #type;
  #data;

  constructor(type, data) {
    if (typeof type != 'string')
      throw ArgumentTypeMismatchError.single(type);

    if (!(data instanceof WorkareaNode || data instanceof WorkareaToolInfo))
      throw ArgumentTypeMismatchError.single(data);

    this.#type = type;
    this.#data = data;
  }

  /**
   *
   * @returns {String}
   */
  getType() {
    return this.#type;
  }

  /**
   *
   * @returns {WorkareaNode|WorkareaToolInfo}
   */
  getData() {
    return this.#data;
  }
}