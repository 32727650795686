<template>
  <div class="column--tool">
    <div class="tool--icon">
      <v-icon color="secondary">mdi-format-columns</v-icon>
    </div>
    <div class="tool--name secondary--text">
      Колонка
    </div>
  </div>
</template>

<script>
export default {
  name: "ColumnTool",
  methods: {}
}
</script>

<style scoped>
</style>