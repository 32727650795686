import {ArgumentTypeMismatchError, typeToToolTemplateName} from "../../../../common";

export default class WorkareaToolInfo {
  #type;
  #dropDirection;
  #defaultValue

  constructor(type, dropDirection, defaultValue) {
    if (typeof type != 'string') throw ArgumentTypeMismatchError.single(type);

    this.#type = type;
    this.#dropDirection = dropDirection;
    this.#defaultValue = defaultValue;
  }

  getType() {
    return this.#type;
  }

  getDefaultValue() {
    return this.#defaultValue;
  }

  getTool() {
    return typeToToolTemplateName(this.#type);
  }

  getDropDirection() {
    return this.#dropDirection;
  }
}