import Vue from 'vue';

import Column from "./Column";
import Heading from "./Heading";
import Image from "./Image";
import Text from "./Text";
import Video from "./Video";
import HTML from "./HTML";

export default () => {
  Vue.component("ColumnTool", Column);
  Vue.component("HeadingTool", Heading);
  Vue.component("ImageTool", Image);
  Vue.component("TextTool", Text);
  Vue.component("VideoTool", Video);
  Vue.component("HTMLTool", HTML);
}
