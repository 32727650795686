<template>
  <div class="layout--work-area__wrapper">
    <div class="layout--work-area page-editor--work-area constructor-impl">
      <template v-for="(child) in node.getChildren()">
        <WorkareaNode :node="child" :key="`${node.getId()}-${child.getId()}`"/>

      </template>
    </div>
  </div>
</template>

<script>

/*

  {
    type: Layout,
  }

  Layout:
  [
    {
      type: Column,
      children: [
        {
          type: heading,
          children: ['Lorem Ipsum head']
        },
        {
          type: image,
          children: ['link to img']
        },
        {
          type: text,
          children: ['Lorem Ipsum...']
        }
      ]
    },
    {
      ...
    }
  ]
 */
import WorkareaNode from "../defs/WorkareaNode";
import WorkareaNodeComponent from "../WorkareaNode";

export default {
  name: "LayoutWorkArea",
  components: {
    WorkareaNode: WorkareaNodeComponent
  },
  props: {
    hideSkeleton : {
      type: Boolean,
      required: false,
      default: false
    },
    node: {
      type: WorkareaNode,
      required: true
    }
  },
  data: () => ({
    childrenOrder: []
  }),
  methods: {
    startMove() {

    }
  },
  created() {
    this.node.setOnUpdate(() => this.$forceUpdate());
    this.childrenOrder.length = this.node.childrenSize();
    this.childrenOrder = this.childrenOrder.fill(0).map((c, i) => i);
  },
}
</script>

<style scoped>
.layout--work-area {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
.vertical--line {
  height: 100%;
  width: 2px;
}
.layout--work-area__wrapper {
  display: flex;
  height: 100%;
}
</style>