import axios from "@/plugins/axios";
import { apiEndpoint, mainDomain } from "../../common";

export default {
  namespaced: true,
  state: {
    name: '',
    surname: '',
    displayname: '',
    role: '',
    id: -1,
    flags: 0,
    classes: [],
    subjects: [],
    patronymic: '',
    password: '',
    isAdmin: false,
    isEmulationNeeded: false
  },
  mutations: {
    setUser(state, user) {
      state.id = user;
      state.name = user.name;
      state.surname = user.surname;
      state.patronymic = user.patronymic;
      state.role = user.role;
      state.displayname = user.displayName;
      state.classes = user.classes;
      state.subjects = user.subjects;
      state.isEmulationNeeded = false;
      state.flags = user.flags;
    }

  },
  actions: {
    async getUser(ctx) {
      let res = await axios.get(apiEndpoint.user);
      if (res.status === 200) {
        ctx.commit('setUser', res.data);
        return true
      } else {
        if (res.status === 401) {
          if (window.location.pathname !== "/") {
            console.log('Tried to access protected path wo auth.')
            window.location.replace(`${mainDomain}/signin`);
            return false
          }
        }
      }
    }
  },
  getters: {
    isLoggedIn(state){
      return state.id != -1;
    }
  }

}