<template>
 <div class="media--weather-widget d-flex">
   <div class="weather--description_icon">
     <v-icon color="secondary">{{ weather.icon }}</v-icon>
   </div>
   <div class="weather--temperature_act secondary--text font-weight-bold">
     {{weather.temp > 0 ? `+${weather.temp}` : weather.temp}}°C
   </div>
 </div>
</template>

<script>
import {weatherUpdateInterval} from '@/common'
export default {
  name: "weather",
  computed: {
    weather: {
      get() {
        return this.$store.state.weather;
      }
    }
  },
  data: () => ({
    updInterval: null
  }),
  beforeMount() {
    this.$store.dispatch('weather/getWeather');
    this.updInterval = setInterval(() => this.$store.dispatch('weather/getWeather'), weatherUpdateInterval);
  },
  beforeDestroy() {
    clearInterval(this.updInterval);
    this.updInterval = null;
  }
}
</script>

<style scoped>
  .weather--description_c {
    flex-direction: column;
    align-items: center;
  }
  .weather--temperature_c {
    margin-left: 12px;
  }
  .weather--description_icon > i {
    font-size: 8vh !important;
  }
  .weather--temperature_act {
    font-size: 4vh;
    margin-left: 12px;
  }
  .media--weather-widget {
    justify-content: center;
    align-items: center;
  }
</style>