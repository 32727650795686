<template>
  <div class="slides--wrap">
    <transition-group name="slide-change" tag="div" class="slides">
      <slide
          v-for="(slide,idx) in urls"
          :key="`slide-${idx}`"
          :template="slide.template"
          :was-mounted="slide.mounted"
          v-show="idx === progress.slide"
          @mounted="() => slide.mounted = true"
      />
  </transition-group>
    <div class="slides--progressbar">
      <div class="slides--progress"></div>
    </div>
  </div>
</template>

<script>
import { slideChangingInterval } from '../../../common'
import Slide from "./Slide";
export default {
  name: "carousel",
  components: {Slide},
  props: {
    // Array of url on slide templates
    slides: {
      type: Array,
      required: true
    }
  },
  computed: {
    nextSlide: {
      get() {
        return this.progress.slide+1 >= this.slides.length ? 0 : this.progress.slide+1;
      }
    }
  },
  data: () => ({
    urls: [],
    progress: {
      bar: 0,
      slide: 0,
      fill: 0
    }
  }),
  methods: {
    incProgress() {
      console.log('changing slides...');
      this.progress.slide++;
      if (this.progress.slide >= this.slides.length) {
        this.progress.slide = 0;
      }
      this.progress.fill = this.progress.slide * this.progress.bar;
      this.$emit('change');
    }
  },
  created() {
    this.slides.forEach(s =>
        this.urls.push({
          template: s,
          mounted: false
        })
    );
    this.progressBar = (100 / this.urls.length).toFixed(2);
  },
  mounted() {
    setInterval(this.incProgress, slideChangingInterval);
  }
}
</script>

<style scoped>
 .slides--wrap {
   display: flex;
   flex-direction: column;
   width: 100%;
 }
 .slides--progressbar {
   height: .5%;
   width: 100%;
 }
 .slides--progress {
   height: 100%;
 }

 .slide-change-enter-to, .slide-change-leave {
   transform: translateX(0);
   opacity: 1;
 }

 .slide-change-enter, .slide-change-leave-to {
   transform: translateX(100%);
   opacity: 0;
 }

 .slide-change-enter-active, .slide-change-leave-active {
   transition: all ease-in-out .5s;
 }

</style>