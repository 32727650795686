<template>
  <div
      ref="node"
      class="workarea--node"
      :class="{'skeleton': !isSkelHidden(), 'node__drag': isNodeDragging, 'root--node': node.isRoot()}"
      :data-node-id="node.getId()"
      @dragover.prevent.stop="(e) => dragOver(e)"
      @dragleave.prevent.stop="dragLeave"
      @drop.prevent.stop="drop"
      @mousemove.stop="mouseMove"
      @mouseleave.stop="mouseLeave"
      @click.prevent.stop="(e) => {if(!node.isSkelHidden()) EventBus.$emit('PageConstructorEditNodeHide')}"
  >
    <div class="skeleton-bar__wrapper" :class="{'show--skeleton-bar': !isNodePhantom() && !isSkelHidden() && showSkeletonBar}">
      <div ref="skel" class="skeleton-bar">
        <v-btn-toggle
            :value="skelBar"
            multiple
            background-color="primary"
            tile
            @change="() => this.skelBar = []"
        >
          <v-btn
              x-small
              outlined
              color="secondary"
              @click.prevent.stop="() => EventBus.$emit('PageConstructorEditNode', node)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
              x-small
              outlined
              color="secondary"
              @click="remove"
              v-if="!node.isRoot()"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
              x-small
              outlined
              color="secondary"
              draggable="true"
              @mousedown="(e) => this.startNodeDragging(e)"
              @mouseup="e => this.dropNode(e)"
              v-if="!(node.isRoot() || node.getType() === pageConstructorTypes.Column)"
          >
            <v-icon>mdi-apps</v-icon>
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <component :is="node.getTemplateName()" v-bind="node.getProps()"
               :style="node.renderStyles()"/>
    <template v-if="node.getType() === pageConstructorTypes.Column && !node.isAddChildrenHidden()">
      <div ref="addChild" class="add--child__wrapper">
        <div class="add--child">
          <v-btn raised color="primary" large icon @click="addLayout"><v-icon>mdi-plus</v-icon></v-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import WorkareaNode from "./defs/WorkareaNode";
import DropDirection from "./defs/DropDirection";
import {EventBus, isChild, pageConstructorTypes} from "../../../common";
import DragInfo from "./defs/DragInfo";

export default {
  name: "WorkareaNode",
  props: {
    node: {
      type: WorkareaNode,
      required: true
    }
  },
  data() {
    /**
     * @type WorkareaNode|null
     */
    this.phantomInserted = null;
    return {
      showSkeletonBar: false,
      isNodeDragging: false,
      skelBar: [],
      DropDirection,
      pageConstructorTypes,
      EventBus
    }
  },
  methods: {
    isChild,
    isSkelHidden() {
      return this.node.isSkelHidden();
    },
    getWidth() {
      return Number.parseInt(this.node.getWidth());
    },
    getHeight() {
      return Number.parseInt(this.node.getHeight());
    },
    mouseLeave(e) {
      e.preventDefault();

      this.showSkeletonBar = false;
    },
    mouseMove(e) {
      e.preventDefault();
      this.showSkeletonBar = e.offsetY < 25;
    },

    addLayout() {
      this.EventBus.$emit('PageConstructorSelectLayout', this.node);
    },

    remove() {
      let parent = this.node.getParent();
      if (parent.getType() === pageConstructorTypes.Layout) {
        if (parent.childrenSize() === 1) {
          parent.remove();
          return;
        }
        let idx = this.node.getIndex();
        let next = parent.getChild( parent.childrenSize()-1 === idx ? idx - 1 : idx + 1);
        next.updateProps({width: Number.parseInt(next.getWidth()) + this.getWidth()});
      }
      this.node.remove();
    },

    insertPhantom(direction, e, before) {
      if (this.phantomInserted && before) {
        let phantomIdx = this.phantomInserted.getParent() ? this.phantomInserted.getIndex() : this.node.findPhantom();
        if (phantomIdx !== before.getIndex()-1) {
          console.log("Bad phantom removed:", phantomIdx, before.getIndex());
          try {
            if (phantomIdx !== -1)
              this.phantomInserted.removePhantom(phantomIdx);
          } catch (err) {
            this.node.removeChild(phantomIdx);
          }
          this.phantomInserted = null;
        }
      }
      if (!this.phantomInserted) {
        console.log("Phantom inserted");
        this.phantomInserted = new WorkareaNode("WorkareaPhantom", {}, DropDirection.REPLACE);

        if (this.node.childrenSize() === 0) {
          this.node.addChild(this.phantomInserted);
        } else {
          if (before) {
            this.node.addChildBefore(before.getIndex(), this.phantomInserted);
            console.log("phantom inserted before", before.getIndex(), before.getId());
            return;
          }
          if (this.node.getType() === pageConstructorTypes.Column) {
            this.node.addChildBefore(0, this.phantomInserted, true);
            return;
          }
          for (let i = 0; i < this.node.childrenSize(); ++i) {
            let child = this.node.getChild(i);
            let childDOM = child.getDOMElement();
            if (this.node.getDropDirection() === DropDirection.VERTICAL) {
              if (childDOM.offsetTop + childDOM.offsetHeight > e.offsetY) {
                if (direction) {
                  this.node.addChildBefore(i, this.phantomInserted);
                } else {
                  this.node.addChildAfter(i, this.phantomInserted);
                }
                return;
              }
            } else if (this.node.getDropDirection() === DropDirection.HORIZONTAL) {
              if (childDOM.offsetLeft + childDOM.offsetWidth > e.offsetX) {
                if (direction) {
                  this.node.addChildBefore(i, this.phantomInserted);
                } else {
                  this.node.addChildAfter(i, this.phantomInserted);
                }
                return;
              }
            }
          }
        }

      }
    },

    dragLeave({target}) {
      if (target.dataset.nodeId !== this.node.getId()) return;
      if (this.node.getType() === pageConstructorTypes.Layout) return;
      console.log("Drag leave event fired: ", this.node.getTemplateName(), this.node.getId())
      if (this.isNodeInsertToParent()) return this.node.getParent().getComponent().dragLeave({target});
      if (this.phantomInserted != null) {
        console.log("PHANTOM REMOVED ON LEAVE FROM", target);
        let phantomIdx = this.phantomInserted.getParent() ? this.phantomInserted.getIndex() : this.node.findPhantom();
        try {
          if (phantomIdx !== -1)
            this.phantomInserted.removePhantom(phantomIdx);
        } catch (e) {
          this.node.removeChild(phantomIdx);
        } finally {
          this.phantomInserted = null;
        }
      }
    },
    dragOver(e, before) {
      console.log("Drag over event fired: ", this.node.getTemplateName(), this.node.getId(), this.node.isRoot());
      if (this.node.getType() === pageConstructorTypes.Layout) return;
      if (!this.canInsert()) return this.node.fireOnDragOver(e, this.node);
      // noinspection EqualityComparisonWithCoercionJS
      if (this.node.getDropDirection() === DropDirection.NONE || !this.isChild(e.target, this.$refs.node)) return;

      let dragInfo = this.node.getDragInfo();

      if (dragInfo == null) return;

      let center = e.target.offsetHeight / 2;
      let cord = center - e.offsetY;
      let direction = (center - cord) > 0;
      // if ((Math.abs(cord) / center) > /*(center > 25 ? 0.3 : 0)*/ 0.3) {
      this.insertPhantom(direction, e, before);

    },

    drop(e) {
      console.log("Event Drop fired: ", this.node.getTemplateName(), this.node.getId());
      if (this.node.getType() === pageConstructorTypes.Layout) return;

      if (this.isNodeInsertToParent()) return this.node.fireOnDrop();

      // noinspection EqualityComparisonWithCoercionJS
      if (this.node.getDropDirection() === DropDirection.NONE || !this.isChild(e.target, this.$refs.node)) return;

      let dragInfo = this.node.getDragInfo();

      if (dragInfo == null) return;

      let data = dragInfo.getData();
      let replace;
      if (data instanceof WorkareaNode) {
        data.unlinkFromParent(false);
        replace = data;
      } else {
        replace = new WorkareaNode(data.getType(), {}, data.getDropDirection());
        replace.setValue(data.getDefaultValue());
      }

      let phantomIdx = this.phantomInserted.getParent() ? this.phantomInserted.getIndex() : this.node.findPhantom();

      this.node.replaceChild(phantomIdx, replace);
      this.phantomInserted = null;
      // if (!this.isNodePhantom()) {
      //   if (this.phantomInserted == null) return;
      //
      //   replace.defineParent(this.node, this.phantomInserted.getIndex());
      //   this.phantomInserted.replace(replace);
      //   this.phantomInserted = null;
      // } else {
      //   replace.defineParent(this.node.getParent(), this.node.getIndex());
      //   this.node.getParent().replaceChild(this.node.getIndex(), replace);
      //   this.node.getParent().getComponent().phantomInserted = null;
      // }
    },



    isNodeInsertToParent() {
      return !(
          this.node.getDropDirection() === DropDirection.HORIZONTAL ||
          this.node.getDropDirection() === DropDirection.VERTICAL
      );
    },

    canInsert() {
      return (
          this.node.getDropDirection() === DropDirection.VERTICAL ||
          this.node.getDropDirection() === DropDirection.HORIZONTAL ||
          this.node.getDropDirection() === DropDirection.REPLACE
      ) && !this.isNodePhantom();
    },

    isNodePhantom() {
      return this.node.getType() === "WorkareaPhantom"
    },

    startNodeDragging() {
      this.node.setDragInfo(new DragInfo(this.node.getType(), this.node));
      this.isNodeDragging = true;
    },

    dropNode() {
      this.node.clearDragInfo();
    },

    beforeDrop() {
    },

    updateWidth() {
      if (this.getWidth() != null) {
        this.$refs.node.style.width = `${(typeof this.getWidth() == 'string') ? this.getWidth().replace('%', '') : this.getWidth()}%`;
      } else {
        this.$refs.node.style.width = '100%';
      }
    },
    updateHeight() {
      if (this.getHeight() != null) {
        this.$refs.node.style.height = `${(typeof this.getHeight() == 'string') ? this.getHeight().replace('%', '') : this.getHeight()}%`;
      } else {
        this.$refs.node.style.height = this.node.getType() === pageConstructorTypes.Column ? '90%' : 'auto';
      }
    }
  },
  created() {
    this.node.setComponent(this);
  },
  mounted() {
    this.node.setDOMElement(this.$refs.node);
    this.node.setOnDragOver((e, before) => this.dragOver(e, before));
    this.node.setOnDrop((e) => this.drop(e));
    this.node.setOnLeave((t) => this.dragLeave(t));
    this.node.setBeforeDrop(() => this.beforeDrop());

    this.updateWidth();
    this.updateHeight();
  },
  updated() {
    this.updateWidth();
    this.updateHeight();
  }
}
</script>

<style scoped>
  .workarea--node {
    width: 100%;
  }
  .root--node {
    height: auto !important;
  }
  .skeleton {
    border: solid #949a9d 2px;
    padding: 5px;
    position: relative;
  }
  .skeleton-bar {
    position: absolute;
    display: flex;
    z-index: 2;
  }
  .skeleton-bar__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: none;
  }
  .show--skeleton-bar {
    display: flex;
    justify-content: center;
  }
  .add--child__wrapper {
    display: flex;
    justify-content: center;
  }
  .add--child__wrapper {
    display: flex;
    justify-content: center;
    max-height: 10%;
  }
</style>