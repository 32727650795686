<template>
 <div class="media--footer d-flex primary elevation-4 secondary--text">
   <footer-time/>
 </div>
</template>

<script>
import Time from "@/components/Footer/Time";

export default {
  name: "index",
  components: {
    'footer-time': Time,
  }
}
</script>

<style scoped>
.media--footer {
  max-height: 10%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>