<template>
  <div class="constructor--text-impl constructor-impl">
    {{ node.getValue() ? node.getValue() : "Текст" }}
  </div>
</template>

<script>
import WorkareaNode from "../defs/WorkareaNode";

export default {
  name: "TextWorkArea",
  props: {
    node: {
      type: WorkareaNode,
      required: true
    }
  },
  created() {
    this.node.setOnUpdate(() => this.$forceUpdate());
  }
}
</script>

<style scoped>

</style>