<template>
  <v-card-text
      @mouseleave="endDrag"
      @mousemove="drag"
      @mouseup="endDrag">
    <span>Выберите положение колонок, перетаскивая линии мышкой</span>
    <div class="layout-selector--layout">
      <svg viewBox="0 0 192 128" ref="svg">
        <rect x="1" y="1" width="190" height="126" stroke="black" stroke-width="2px" fill="transparent"/>
        <line
            v-for="({x1, x2, y1, y2}, idx) in lines"
            :ref="`svgLine-${idx}`"
            :key="`svgLine-${idx}`"
            :x1="x1"
            :x2="x2"
            :y1="y1"
            :y2="y2"
            :data-default-x="x1"
            stroke="black"
            stroke-width="2px"
            @mousedown.prevent="startDrag"
            @mousemove.prevent="drag"
            @mouseup.prevent="endDrag"
        />
      </svg>
    </div>
  </v-card-text>
</template>

<script>
import {EventBus} from "../../../../common";
export default {
  name: "LayoutSelectorPhase2",
  props: {
    layout: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    lines: [],
    dragging: {
      elem: null,
      lineidx: 0,
      min: 0,
      max: 0
    }
  }),
  created() {
    this.init();
    EventBus.$on('PageConstructorResetSelectLayout', this.init);
  },
  methods: {
    init() {
      for (let i = 1; i < this.layout; ++i) {
        let x = i * (Math.round(192 / this.layout))
        this.lines[i - 1] = {
          x1: x,
          y1: 1,
          x2: x,
          y2: 126
        }
      }
    },
    getMousePos(evt) {
      let CTM = this.$refs.svg.getScreenCTM();
      return (evt.clientX - CTM.e) / CTM.a
    },
    moveElem(pos) {
      if (pos < this.dragging.min || pos > this.dragging.max) return;
      this.dragging.elem.setAttribute("x1", pos);
      this.dragging.elem.setAttribute("x2", pos);
    },
    startDrag(e) {
      this.dragging.elem = e.target;
      this.dragging.lineidx = this.lines.findIndex(l => this.dragging.elem.getAttribute('data-default-x') == l.x1);
      if (!this.dragging.lineidx && this.dragging.lineidx !== 0) return;
      this.dragging.min = this.dragging.lineidx > 0 ? this.lines[this.dragging.lineidx - 1].x1 + 24 : 24;
      this.dragging.max = this.dragging.lineidx === this.lines.length - 1 ? 166 : this.lines[this.dragging.lineidx + 1].x1 - 24;
    },
    drag(e) {
      if (!this.dragging.elem) return;
      this.moveElem(this.getMousePos(e));
      this.getPosition();
    },
    endDrag() {
      if (!this.dragging.elem) return;
      this.dragging = {
        elem: null,
        lineidx: 0,
        min: 0,
        max: 0
      };
    },
    getPosition() {
      let pos = [];
      this.lines.concat([192]).reduce((a, e, i) => {
        let c = i < this.lines.length ? Number.parseInt(this.$refs[`svgLine-${i}`][0].getAttribute('x1')) : 192;
        pos.push(Math.round( (c - a) / 192 * 100));
        return c;
      }, 0);
      return pos;
    }
  }
}
</script>

<style scoped>

</style>