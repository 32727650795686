import {ArgumentTypeMismatchError} from "../../../../common";

export default class WorkareaNodeStyles {
  #styles = [];

  constructor(styles) {
    if (styles != null) {
      if (!(typeof styles == 'object' && styles instanceof Array)) throw ArgumentTypeMismatchError.single(styles);
      this.#styles = styles;
    }
  }

  static deserialize(obj) {
    return new WorkareaNodeStyles(obj);
  }

  render() {
    if (this.#styles.length === 0) return '';

    return this.#styles.map(style => {
      const {name, value} = style;
      return `${name}: ${typeof value == 'string' ? value : value.join(' ')}`;
    }).join(';');

  }

  add(name, value) {
    if (typeof name != "string" || !(typeof value == 'string' || value instanceof Array))
      throw ArgumentTypeMismatchError.multiple([name, value]);
    name = name.trim();
    let idx = this.#styles.findIndex(e => e.name === name);
    if (idx !== -1) {
      this.#styles[idx] = {name, value};
      return;
    }

    this.#styles.push({name, value});
  }

  remove(name) {
    if (typeof name != "string") throw ArgumentTypeMismatchError.single(name);
    let idx = this.#styles.findIndex(s => s.name === name);
    if (idx === -1) return false;
    delete this.#styles[idx];
    return true;
  }

  get(name) {
    if (typeof name != "string") throw ArgumentTypeMismatchError.single(name);
    let f = this.#styles.find(s => s.name === name);
    return f ? f.value : f;
  }

  serialize() {
    return this.#styles;
  }
}