<template>
  <div class="tool--properties column--properties">
    <!--    <v-subheader>Ширина</v-subheader>-->
    <!--    <v-text-field label="Ширина" v-model="width_" type="number"/>-->
    <!--    -->
    <v-btn color="primary" @click="editLayout">Изменить макет</v-btn>
    <template v-if="layoutChange">
      <span>Количество столбцов не совпадает с предыдущим, при применении изменений лишние столбцы <strong>будут удалены</strong>, либо <strong>добавлены новые</strong>, начиная с правых.</span>
      <div class="d-flex">
        <v-btn outlined color="primary" @click="() => layoutChange = null">Отменить</v-btn>
        <v-spacer/>
        <v-btn raised color="primary" @click="() => submitLayoutEdit()">Подтвердить</v-btn>
      </div>
    </template>
    <v-subheader>Рамка</v-subheader>
    <v-color-picker v-model="props.border.color" hide-inputs/>
    <v-text-field hide-details outlined dense label="Размер" v-model.number="props.border.sides" type="number" :max="5" :min="0"/>

    <v-subheader>Фон</v-subheader>
    <v-color-picker v-model="props.backgroundColor" hide-inputs/>
    <v-file-input hide-details outlined dense label="Фон-картинка" accept="image/*" @change="addImgBackground" :loading="bgImgLoader"/>
    <v-spacer/>
    <v-btn color="primary" @click="() => node.hideSkeleton()">{{ node.isSkelHidden() ? 'Показать скелет' : 'Спрятать скелет'}}</v-btn>
  </div>
</template>

<script>
import WorkareaNode from "../defs/WorkareaNode";
import {compressImage, EventBus, pageConstructorTypes} from "../../../../common";
import DropDirection from "../defs/DropDirection";

export default {
  name: "LayoutProperties",
  props: {
    node: {
      type: WorkareaNode,
      required: true
    }
  },
  data: () => ({
    props: {
      layout: [],
      border: {
        type: 'solid',
        color: '#000000',
        sides: 0
      },
      backgroundColor: '#fff',
      backgroundImg: ''
    },
    updateTimestamp: 0,
    updateTimeout: 0,
    update: false,
    bgImgLoader: false,
    layoutChange: null,
    EventBus
  }),
  methods: {
    editLayout() {
      EventBus.$once('PageConstructorSelectLayoutDryRun', nLayout => {
        if (nLayout.length !== this.node.childrenSize()) {
          this.layoutChange = nLayout;
        } else {
          this.submitLayoutEdit(nLayout);
        }
      });
      EventBus.$emit('PageConstructorPropertiesSelectLayout', this.node)
    },
    submitLayoutEdit(layout) {
      if (layout == null) layout = this.layoutChange;
      let childrenSize = this.node.childrenSize();

      for (let i = 0; i < Math.max(layout.length, childrenSize); ++i) {
        let child = this.node.getChild(i);
        let col = layout.length > i ? layout[i] : null;
        if (child && col) {
          child.updateProps({width: col}, false);
        } else if (!child && col) {
          this.node.addChild(new WorkareaNode(pageConstructorTypes.Column, {width: col}, DropDirection.VERTICAL), false);
        } else if (child && !col) {
          child.remove(false);
        }
      }

      this.node.forceUpdate(true, 1);
      this.layoutChange = null;
    },
    /**
     *
     * @param img {File}
     */
    addImgBackground(img) {
      if (img == null) {
        this.props.backgroundImg = "";
        return;
      }
      this.bgImgLoader = true;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = () => {
        compressImage(reader.result).then(a => {
          this.props.backgroundImg = `url(${a})`;
          this.bgImgLoader = false;
        });
      }
    }
  },
  created() {
    this.props.layout = this.node.getChildren().map(child => Number.parseInt(child.getWidth()));
    let border = this.node.getStyle("border");
    if (border && typeof border != 'string' && border.length >= 3) {
      this.props.border.type = border[0];
      this.props.border.color = border[1];
      this.props.border.sides = Number.parseInt(border[2]);
    }
    let backgroundColor = this.node.getStyle("background-color");
    if (backgroundColor && typeof backgroundColor == 'string') {
      this.props.backgroundColor = backgroundColor;
    }
    let backgroundImg = this.node.getStyle("background-image");
    if(backgroundImg && typeof backgroundImg == 'string') {
      this.props.backgroundImg = backgroundImg;
    }
  },
  watch: {
    props: {
      handler() {
        let upd = this.updateTimestamp + 1000 < Date.now();
        this.node.updateProps({width: this.props.width_}, upd);

        let border = this.props.border;
        this.node.addStyle("border", [border.type, border.color, `${border.sides}px`])
        this.node.addStyle("background-color", this.props.backgroundColor);
        if (this.props.backgroundImg) {
          this.node.addStyle("background", `${this.props.backgroundImg} 100% 100% no-repeat`);
          if (!this.node.getStyle('background-size'))
            this.node.addStyle("background-size", 'cover');
        }

        if (upd) {
          this.updateTimestamp = Date.now();
          this.updateTimeout = setTimeout(() => {
            if (this.update) this.node.forceUpdate(false);
          }, 1000);
          this.node.forceUpdate(false);
        } else if (this.updateTimeout && !this.update) {
          this.update = true;
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>