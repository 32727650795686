<template>
  <div ref="column" class="column--work-area page-editor--work-area constructor-impl">
    <div class="column--content">
      <template v-for="child in node.getChildren()">
        <WorkareaNode :node="child" :key="`${node.getId()}-${child.getId()}`"/>
      </template>
    </div>
  </div>
</template>

<script>
import WorkareaNode from "../defs/WorkareaNode";
import WorkareaNodeComponent from "../WorkareaNode";

export default {
  name: "ColumnWorkArea",
  components: {
    WorkareaNode: WorkareaNodeComponent
  },
  props: {
    node: {
      type: WorkareaNode,
      required: true
    },
    width: {
      type: [Number, String],
      required: false,
      default: 100
    }
  },
  data: () => ({
    childrenOrder: []
  }),

  created() {
    this.node.setOnUpdate(() => this.$forceUpdate());
    this.childrenOrder.length = this.node.childrenSize();
    this.childrenOrder = this.childrenOrder.fill(0).map((c, i) => i);
  },

  mounted() {
    // if (typeof this.width == 'string' && this.width.endsWith('%')) this.width = this.width.slice(0, -1);
    // this.$refs['column'].style.width = `${this.width}%`;
  }
}
</script>

<style scoped>
.column--work-area {
  height: 100%;
  width: 100%;
}
.column--content {
  height: 100%;
  width: 100%;
}
</style>