<template>
  <div class="workarea--phantom primary">

  </div>
</template>

<script>
import WorkareaNode from "./defs/WorkareaNode";

export default {
  name: "WorkareaPhantom",
  props: {
    node: {
      type: WorkareaNode,
      required: true
    }
  }
}
</script>

<style scoped>
  .workarea--phantom {
    width: 100%;
    height: 100%;
    max-height: 50px;
  }
</style>