import Vue from 'vue';

import Column from "./Column";
import Heading from "./Heading";
import Image from "./Image";
import Text from "./Text";
import Video from "./Video";
import Layout from "./Layout";
import HTML from "./HTML";

export default () => {
  Vue.component("ColumnProperties", Column);
  Vue.component("HeadingProperties", Heading);
  Vue.component("ImageProperties", Image);
  Vue.component("TextProperties", Text);
  Vue.component("VideoProperties", Video);
  Vue.component("LayoutProperties", Layout);
  Vue.component("HTMLProperties", HTML);
}
