<template>
 <div class="pages--list">
    <page-list/>
 </div>
</template>

<script>
import PageList from "../../components/Admin/PageList";
export default {
  name: "Home",
  components: {PageList},

}
</script>

<style scoped>

.pages--list {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
</style>