import Vue from "vue";
import Column from "./Column";
import Heading from "./Heading";
import Image from "./Image";
import Layout from "./Layout";
import Text from "./Text";
import Video from "./Video";
import HTML from "./HTML";

export default () => {
  Vue.component("ColumnWorkArea", Column);
  Vue.component("HeadingWorkArea", Heading);
  Vue.component("ImageWorkArea", Image);
  Vue.component("LayoutWorkArea", Layout);
  Vue.component("TextWorkArea", Text);
  Vue.component("VideoWorkArea", Video);
  Vue.component("HTMLWorkArea", HTML);
}