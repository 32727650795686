import axios from '../../plugins/axios'
import {apiEndpoint, Load} from '../../common'

export default {
  namespaced: true,
  state: {
    curPage: {
      id: 0,
      name: "",
      creationTimestamp: 0,
      template: ''
    },
    pages: []
  },
  actions: {
    async getPages({commit}, { load = new Load() }) {
      load.setLoadFunc(async () => {
        let r = await axios.get(apiEndpoint.pageList);
        if(r.status !== 200) throw `Status code: ${r.status}\nResponse: ${r.data}`;
        return r;
      });
      load.setFailHint('Failed to fetch pages list.');

      commit('setPages', (await load.start()).data);
    },
    async deletePage({commit}, { id = 0, load = new Load() }) {
      load.setLoadFunc(async () => {
        if (!id) throw `ID (${id}) must be provided.`;
        let r = await axios.delete(`${apiEndpoint.pageList}/${id}`);
        if (r.status !== 200) {
          commit('showError', r.data, {root: true})
          throw `Status code: ${r.status}.\nResponse${r.data}.`;
        }
        return r;
      });
      load.setFailHint('Failed to delete page.');

      await load.start();

      commit('removePage', id);
    }
  },
  mutations: {
    removePage(state, id) {
      state.pages = state.pages.filter(p => p.id !== id);
    },
    setPages(state, pages) {
      state.pages = pages.map(p => ({...p, selected: false}));
    },
    setCurPage(state, page) {
      state.curPage = page;
    }
  }
}