<template>
  <div ref="html" class="html--work-area page-editor--work-area constructor-impl">
    <div class="html--content">
      <div v-html="node.getValue()"></div>
    </div>
  </div>
</template>

<script>
import WorkareaNode from "../defs/WorkareaNode";

export default {
  name: "HTMLWorkArea",
  props: {
    node: {
      type: WorkareaNode,
      required: true
    }
  },
  data: () => ({
    childrenOrder: [],
  }),

  created() {
    this.node.setOnUpdate(() => this.$forceUpdate());
    this.childrenOrder.length = this.node.childrenSize();
    this.childrenOrder = this.childrenOrder.fill(0).map((c, i) => i);
  },

  mounted() {
    // if (typeof this.width == 'string' && this.width.endsWith('%')) this.width = this.width.slice(0, -1);
    // this.$refs['column'].style.width = `${this.width}%`;
  }
}
</script>

<style scoped>
.html--work-area {
  height: 100%;
  width: 100%;
}
.html--content {
  height: 100%;
  width: 100%;
}
</style>