<template>
  <div class="video--tool page-editor--tool">
    <div class="tool--icon">
      <v-icon color="secondary">mdi-video-box</v-icon>
    </div>
    <div class="tool--name secondary--text">
      Видео
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoTool"
}
</script>

<style scoped>

</style>