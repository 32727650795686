<template>
  <div class="html--properties tool--properties">
    <v-textarea hide-details outlined dense label="HTML" v-model="props.value"/>

    <v-text-field hide-details outlined dense label="Ширина" v-model.number="props.width" type="number" :min="0" :max="100"/>
    <v-subheader>Рамка</v-subheader>
    <v-color-picker v-model="props.border.color" hide-inputs/>
    <v-text-field hide-details outlined dense label="Размер" v-model.number="props.border.sides" type="number" :max="5" :min="0"/>
    <v-spacer/>
    <v-btn color="primary" @click="() => node.hideSkeleton()">{{ node.isSkelHidden() ? 'Показать скелет' : 'Спрятать скелет'}}</v-btn>
  </div>
</template>

<script>
import WorkareaNode from "../defs/WorkareaNode";

export default {
  name: "HTMLProperties",
  props: {
    node: {
      type: WorkareaNode,
      required: true
    }
  },
  data: () => ({
    alignment: 0,
    props: {
      value: '<p>HTML</p>',
      border: {
        type: 'solid',
        color: '#000000',
        sides: 0
      },
      width: 100,
      margin: '0'
    },
    loading: false,
    updateTimestamp: 0,
    updateTimeout: 0,
    update: false
  }),
  created() {
    this.props.value = this.node.getValue() || this.props.value;
    let width = this.node.getStyle('width')
    this.props.width = width ? Number.parseFloat(width) : 100;

    let border = this.node.getStyle("border");
    if (border && typeof border != 'string' && border.length >= 3) {
      this.props.border.type = border[0];
      this.props.border.color = border[1];
      this.props.border.sides = Number.parseInt(border[2]);
    }
  },
  watch: {
    props: {
      handler() {
        let upd = this.updateTimestamp + 1000 < Date.now();

        let border = this.props.border;
        this.node.addStyle("border", [border.type, border.color, `${border.sides}px`])
        this.node.addStyle('width', `${this.props.width}%`);

        this.node.setValue(this.props.value);

        if (upd) {
          this.updateTimestamp = Date.now();
          this.updateTimeout = setTimeout(() => {
            if (this.update) this.node.forceUpdate(false);
          }, 1000);
          this.node.forceUpdate(false);
        } else if (this.updateTimeout && !this.update) {
          this.update = true;
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>