import {ArgumentOutOfRangeError, ArgumentTypeMismatchError, generateString, typeToWorkareaTemplateName} from "../../../../common";
import WorkareaNodeStyles from "./WorkareaNodeStyles";
import DropDirection from "./DropDirection";
import DragInfo from "./DragInfo";

export default class WorkareaNode {
  static nodes = {};
  #id = generateString(8);
  #type;
  #styles;
  #parent;
  #index;
  #value;
  #children;
  #props;
  #onDragOverNonUsedNodes;
  #onDropToNonUsedNodes;
  #beforeDropCb;
  #onLeaveFromNonUsedNodes;
  #onUpdate;
  #dropDirection;
  #root;
  #curDragInfo;
  #globalHideSkeleton;
  #component;
  #DOMNode;
  #globalHideAddChildren;

  static clearNodes() {
    WorkareaNode.nodes = {};
  }

  constructor(type, props, dropDirection, parent, styles, value, children) {
    if (typeof type != "string") throw ArgumentTypeMismatchError.single(type);
    this.#type = type;

    if (typeof props != 'object') throw ArgumentTypeMismatchError.single(props);
    this.#props = props;
    this.#props.node = this;

    if (typeof dropDirection != 'number') throw ArgumentTypeMismatchError.single(dropDirection);
    if (dropDirection > Object.keys(DropDirection).length || dropDirection < 0) throw ArgumentOutOfRangeError.single(dropDirection);
    this.#dropDirection = dropDirection;

    if (styles == null) {
      this.#styles = new WorkareaNodeStyles();
    } else {
      if (!(typeof styles == "object" && (styles instanceof Array || styles instanceof WorkareaNodeStyles))) {
        throw ArgumentTypeMismatchError.single(styles);
      }
      this.#styles = styles instanceof Array ? this.#styles = new WorkareaNodeStyles(styles) : styles;
    }

    if (value != null) {
      this.#value = value;
    }

    if (children != null) {
      if (!(typeof children == 'object' && children instanceof Array)) throw ArgumentTypeMismatchError.single(children);
      this.#children = children;
    }

    this.#root = this.getId();
    WorkareaNode.nodes[this.getId()] = this;
  }

  /**
   *
   * @returns {string}
   */
  getId() {
    return this.#id;
  }

  /**
   *
   * @returns {string}
   */
  render() {
    let templateName = typeToWorkareaTemplateName(this.#type);
    let inner = "";
    if (this.#value != null) {
      inner = this.#value.toString();
    } else if (this.#children != null) {
      inner = this.#children.reduce((a, e) => a + e.render(), "")
    }
    return `<${templateName} style="${this.#styles.render()}">${inner}</${templateName}>`
  }

  addStyle(name, value) {
    this.#styles.add(name, value)
    this.forceUpdate();
  }

  renderStyles() {
    return this.#styles.render();
  }

  /**
   *
   * @param name {String}
   * @returns {boolean}
   */
  removeStyle(name) {
    let r = this.#styles.remove(name);
    this.forceUpdate();
    return r;
  }

  /**
   *
   * @param name {String}
   * @returns {Array|String}
   */
  getStyle(name) {
    return this.#styles.get(name);
  }

  /**
   *
   * @param node {WorkareaNode}
   *
   * @returns {WorkareaNode}
   */
  addChild(node, update = true) {
    if (!(typeof node == 'object' && node instanceof WorkareaNode))
      throw ArgumentTypeMismatchError.single(node);

    if (!this.#children) this.#children = [];

    let idx = this.#children.push(node);

    node.defineParent(this, idx-1);

    if (update)
      this.forceUpdate();

    return node;
  }

  /**
   *
   * @param idx {Number}
   * @param node {WorkareaNode}
   *
   * @returns {WorkareaNode}
   */
  addChildAfter(idx, node) {
    if (!(node instanceof WorkareaNode)) throw ArgumentTypeMismatchError.single(node);

    if (!this.#children) {
      this.addChild(node);
      return node;
    }

    if (idx > this.#children.length || idx < 0) throw ArgumentOutOfRangeError.single(idx);

    if (idx === this.#children.length-1) {
      this.addChild(node);
      return node;
    }

    this.#children.splice(idx, 0, node);
    this.remapChildren(idx);

    return node;
  }

  /**
   *
   * @param idx {Number}
   * @param node {WorkareaNode}
   *
   * @returns {WorkareaNode}
   */
  addChildBefore(idx, node, update = true) {
    if (!(node instanceof WorkareaNode)) throw ArgumentTypeMismatchError.single(node);

    if (!this.#children) {
      this.addChild(node, update);
      return node;
    }

    if (idx > this.#children.length || idx < 0) throw ArgumentOutOfRangeError.single(idx);

    if (idx === 0) {
      this.#children.unshift(node);
      this.remapChildren(0, update);
      return node;
    }

    this.#children.splice(idx, 0, node);
    this.remapChildren(idx-1, false);

    if (update)
      this.forceUpdate(true);

    return node;
  }

  remapChildren(after, update = true) {
    this.#children.forEach((node, idx) => {
      if (after && idx <= after) return;
      node.defineParent(this, idx);
    });
    if (update)
      this.forceUpdate();
  }

  /**
   *
   * @param idx
   * @param node
   * @param update
   * @returns {WorkareaNode}
   */
  replaceChild(idx, node, update = true) {
    if (!(node instanceof WorkareaNode)) throw ArgumentTypeMismatchError.single(node);

    if (!this.#children) {
      this.addChild(node, update);
      return node;
    }

    if (idx > this.#children.length || idx < 0) throw ArgumentOutOfRangeError.single(idx);

    this.#children[idx] = node;
    node.defineParent(this, idx);

    if (update)
      this.forceUpdate();

    return node;
  }

  /**
   *
   * @param idx {Number}
   * @returns {null|WorkareaNode}
   */
  getChild(idx) {
    if (!this.#children) {
      console.warn(`Tried to get child with no children. Node: ${this.#type}`);
      return null;
    }
    return this.#children[idx];
  }

  removeChild(idx, update = true) {
    if (!this.#children) {
      console.warn(`Tried to remove child with no children. Node: ${this.#type}`);
      return;
    }
    this.#children.splice(idx, 1);
    this.remapChildren(idx-1);
    if (update)
      this.forceUpdate();
  }

  removePhantom(index = this.getIndex(), update = true) {
    if (index === -1) return;
    if (this.getParent().getChild(index).getType() === 'WorkareaPhantom') {
      this.remove(update);
    }
  }

  remove(update = true) {
    return this.getParent().removeChild(this.getIndex(), update);
  }

  findPhantom() {
    return this.#children.findIndex(e => e.getType() === 'WorkareaPhantom');
  }

  /**
   *
   * @param node {WorkareaNode}
   * @param update
   * @returns {WorkareaNode}
   */
  replace(node, update = true) {
    return this.getParent().replaceChild(this.getIndex(), node, update);
  }

  // Update forced
  removePhantoms() {
    if (this.hasChildren())
      this.#children.forEach((c, i) => c.getType() === 'WorkareaPhantom' ? this.removeChild(i) : c.removePhantoms());

    if (this.#component.insertedPhantom) {
      this.#component.insertedPhantom = null;
      this.forceUpdate();
    }
  }

  /**
   *
   * @returns {number}
   */
  childrenSize() {
    if (!this.#children) return 0;
    return this.#children.length;
  }

  changeOrder(order, update = true) {
    if (!this.#children) {
      console.warn(`Tried to change order with no children. Node: ${this.#type}`);
      return;
    }
    if (!(typeof order == 'object' && order instanceof Array))
      throw ArgumentTypeMismatchError.single(order);

    this.#children = order.map(i => this.#children[i]);
    if (update)
      this.forceUpdate();
  }

  /**
   *
   * @returns {Array<WorkareaNode>}
   */
  getChildren() {
    return this.#children;
  }

  /**
   *
   * @returns {String}
   */
  getValue() {
    return this.#value;
  }

  setValue(val) {
    this.#value = val;
    this.forceUpdate();
  }

  /**
   *
   * @returns {Object}
   */
  getProps() {
    return this.#props;
  }

  getWidth() {
    return this.getProps() == null ? null : this.getProps().width;
  }

  getHeight() {
    return this.getProps() == null ? null : this.getProps().height;
  }

  /**
   *
   * @returns {string}
   */
  getTemplateName() {
    return typeToWorkareaTemplateName(this.#type);
  }

  setOnUpdate(onUpdate) {
    if (typeof onUpdate != 'function')
      throw ArgumentTypeMismatchError.single(onUpdate);

    this.#onUpdate = onUpdate;
  }

  forceUpdate(isRecursive, deep = -1) {
    if (this.#component) {
      this.#component.$forceUpdate();
    }
    if (this.#onUpdate) {
      this.#onUpdate();
    } else if (!this.#component) {
      console.warn(`Update forced for node with id: [${this.#id}] (${this.getTemplateName()}) with no listeners`);
      return;
    }
    console.warn(`Update forced for node with id: [${this.#id}] (${this.getTemplateName()})`);

    if (isRecursive) {
      if (deep === 0 || !this.#children) return;
      this.#children.forEach(node => deep > 0 ? node.forceUpdate(true, deep - 1) : node.forceUpdate(deep === -1 && isRecursive));
    }
  }

  updateProps(props, update = true) {
    if (typeof props != 'object') throw ArgumentTypeMismatchError.single(props);
    Object.keys(props).forEach(name => {
      this.#props[name] = props[name];
    });
    this.forceUpdate(update);
  }

  /**
   *
   * @returns {String}
   */
  getType() {
    return this.#type;
  }

  /**
   *
   * @returns {boolean}
   */
  isEmpty() {
    return this.hasChildren() || this.hasValue();
  }

  /**
   *
   * @returns {boolean}
   */
  hasValue() {
    return !!this.#value;
  }

  /**
   *
   * @returns {boolean}
   */
  hasChildren() {
    return this.#children && this.#children.length > 0;
  }

  /**
   *
   * @returns {Number}
   */
  getDropDirection() {
    return this.#dropDirection;
  }

  /**
   *
   * @param node {WorkareaNode}
   * @param idx {Number}
   */
  defineParent(node, idx) {
    this.#parent = node;
    this.#index = idx;
    this.#root = node.getRoot().getId();
  }

  unlinkFromParent(update = true) {
    if(!this.getParent()) return;
    this.remove(update);

    this.#root = this.getId();
    this.#parent = null;
    this.#index = 0;
    if (update)
      this.forceUpdate();
  }

  /**
   *
   * @returns {WorkareaNode}
   */
  getParent() {
    return this.#parent;
  }

  /**
   *
   * @returns {Number}
   */
  getIndex() {
    return this.#index;
  }

  /**
   *
   * @returns {WorkareaNode}
   */
  getRoot() {
    return WorkareaNode.nodes[this.#root];
  }

  /**
   *
   * @returns {DragInfo|null}
   */
  getDragInfo() {
    // noinspection EqualityComparisonWithCoercionJS
    if (this.#id == this.#root) {
      return this.#curDragInfo;
    } else {
      return this.getRoot().getDragInfo();
    }
  }

  setDragInfo(info) {
    if (!(info instanceof DragInfo))
      throw ArgumentTypeMismatchError.single(info);

    // noinspection EqualityComparisonWithCoercionJS
    if (this.#id == this.#root) {
      console.log('drag info set to: ', info);
      this.#curDragInfo = info;
    } else {
      this.getRoot().setDragInfo(info);
    }

  }

  clearDragInfo() {
    // noinspection EqualityComparisonWithCoercionJS
    if (this.#id == this.#root) {
      console.log('drag info cleared');
      this.#curDragInfo = null;
    } else {
      this.getRoot().clearDragInfo();
    }
  }

  isSkelHidden() {
    if (this.#id == this.#root) {
      return this.#globalHideSkeleton;
    } else {
      return this.getRoot().isSkelHidden();
    }
  }

  hideSkeleton() {
    // noinspection EqualityComparisonWithCoercionJS
    if (this.#id == this.#root) {
      let v = !this.#globalHideSkeleton;
      this.#globalHideSkeleton = v;
      this.#globalHideAddChildren = v;
      this.forceUpdate(true);
    } else {
      this.getRoot().hideSkeleton();
    }
  }

  isAddChildrenHidden() {
    if (this.#id == this.#root) {
      return this.#globalHideAddChildren;
    } else {
      return this.getRoot().isAddChildrenHidden();
    }
  }

  hideAddChildren() {
    if (this.#id == this.#root) {
      console.log("started hide add children");
      this.#globalHideAddChildren = false;
      this.forceUpdate(true);
      console.log("add children hidden");
    } else {
      this.getRoot().hideAddChildren();
    }
  }

  showAddChildren() {
    if (this.#id == this.#root) {
      this.#globalHideAddChildren = true;
      this.forceUpdate(true);
    } else {
      this.getRoot().showAddChildren();
    }
  }


  isRoot() {
    return this.#id == this.#root;
  }

  setDOMElement(el) {
    this.#DOMNode = el;
  }

  getDOMElement() {
    return this.#DOMNode;
  }

  setComponent(c) {
    this.#component = c;
  }

  getComponent() {
    return this.#component;
  }

  setBeforeDrop(fn) {
    this.#beforeDropCb = fn;
  }

  getBeforeDrop() {
    return this.#beforeDropCb;
  }

  fireBeforeDrop() {
    console.log("Calling Before Drop Callback")
    if (this.#beforeDropCb) {
      this.#beforeDropCb();
    } else {
      console.warn("There is no before drop callback yet.");
    }
  }

  setOnDrop(fn) {
    this.#onDropToNonUsedNodes = fn;
  }

  getOnDrop() {
    return this.#onDropToNonUsedNodes;
  }

  fireOnDrop() {
    console.log("Throwing Drop Event to Parent Node");
    let onDrop = this.getParent().getOnDrop();
    if (onDrop) {
      onDrop({target: this.getParent().getDOMElement()});
    }
  }

  setOnLeave(fn) {
    this.#onLeaveFromNonUsedNodes = fn;
  }

  getOnLeave() {
    return this.#onLeaveFromNonUsedNodes;
  }

  fireOnLeave() {
    console.log("Throwing Leave Event to Parent Node");
    let onLeave = this.getParent().getOnLeave();
    if (onLeave) {
      onLeave();
    }
  }

  setOnDragOver(fn) {
    this.#onDragOverNonUsedNodes = fn;
  }

  getOnDragOver() {
    return this.#onDragOverNonUsedNodes
  }

  fireOnDragOver(e, before) {
    let onDrag = this.getParent().getOnDragOver();
    if (onDrag) {
      onDrag({target: this.getParent().getDOMElement(), offsetY: e.offsetY, offsetX: e.offsetX}, before);
    }
  }

  deserialize(obj) {
    console.log('deserializing obj:', obj);
    if (obj.template) {
      obj = JSON.parse(obj.template);
    }
    this.#id = obj.id;
    this.#type = obj.type;
    this.#styles = WorkareaNodeStyles.deserialize(obj.styles);
    this.#index = obj.index;
    this.#value = obj.value;
    this.#children = obj.children ? obj.children.map(child => new WorkareaNode(child.type, child.props, child.dropDirection).deserialize(child)) : null;
    this.#props = obj.props;
    this.#props.node = this;
    this.#dropDirection = obj.dropDirection;
    this.#root = obj.root;
    this.#globalHideSkeleton = obj.globalHideSkeleton
    this.#globalHideAddChildren = obj.globalHideAddChildren;
    WorkareaNode.nodes[this.getId()] = this;
    console.log("deserialized obj: ", this.getId(), this.#type, this.#styles.render(), this.#index, this.#value, this.#children, this.#props)
    return this
  }

  serialize() {
    return {
      id: this.getId(),
      type: this.getType(),
      styles: this.#styles.serialize(),
      index: this.getIndex(),
      value: this.getValue(),
      children: this.getChildren() ? this.getChildren().map(child => child.serialize()) : null,
      props: this.getProps(),
      dropDirection: this.getDropDirection(),
      root: this.#root,
      globalHideSkeleton: this.#globalHideSkeleton,
      globalHideAddChildren: this.#globalHideAddChildren
    };

  }
  // calcInsertIndex(mX, mY) {
  //
  // }
  //
  // insertPhantom() {
  //   this.addChildBefore(this.getIndex(), new WorkareaNode("WorkareaPhantom", {}, DropDirection.REPLACE));
  // }
}