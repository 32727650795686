<template>
  <div class="heading--tool page-editor--tool">
    <div class="tool--icon">
      <v-icon color="secondary">mdi-xml</v-icon>
    </div>
    <div class="tool--name secondary--text">
      HTML
    </div>
  </div>
</template>

<script>
export default {
  name: "HTMLTool"
}
</script>

<style scoped>
</style>