<template>
  <div class="workarea--properties">
    <component :is="typeToPropertiesTemplateName(node.getType())" v-bind="{node}"/>
  </div>
</template>

<script>
import WorkareaNode from "./defs/WorkareaNode";
import {typeToPropertiesTemplateName} from "../../../common";

export default {
  name: "WorkareaProperties",
  props: {
    node: {
      type: WorkareaNode,
      required: true
    }
  },
  methods: {
    typeToPropertiesTemplateName
  }
}
</script>

<style>
.tool--properties {
  display: flex;
  flex-direction: column;
  height: 99vh;
  max-width: 300px;
}
</style>

<style scoped>
.workarea--properties {
  padding: 5px;
}
</style>