<template>
  <div class="image--tool page-editor--tool">
    <div class="tool--icon">
      <v-icon color="secondary">mdi-image</v-icon>
    </div>
    <div class="tool--name secondary--text">
      Изображение
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageTool"
}
</script>

<style scoped>

</style>