import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user.js';
import weather from './modules/weather';
import alarmSchedule from './modules/alarmSchedule'
import pageList from "./modules/pageList";

import axios from '../plugins/axios';

import {apiEndpoint, compressImage} from '@/common';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: {
      title: '',
      message: '',
      fatal: false,
      show: false
    },

    dialog: {
      name: '',
      data: null,
      answer: null
    }
  },
  mutations: {
    showDialog(state, { name, data }) {
      state.dialog.name = name;
      state.dialog.data = data;
    },
    hideDialog(state) {
      state.dialog.name = '';
      state.dialog.data = null;
    },
    clearDialog(state) {
      if(state.dialog.name) {
        state.dialog.name = '';
        state.dialog.data = null;
      }
      state.dialog.answer = null;
    },
    setDialogAnswer(state, data) {
      state.dialog.answer = data;
    },
    showError(state, err) {
      state.isPageLoading = false;
      state.error.title = err.title;
      state.error.fatal = err.fatal;
      state.error.message = err.content;
      state.error.show = true;
    },
    clearError(state) {
      state.error = {
        title: '',
        message: '',
        fatal: false,
        show: false
      }
    }
  },
  actions: {
    async uploadFiles(ctx, files) {
      let formData = new FormData();
      let filesHandler = () => new Promise(resolve => {
        if(files instanceof Array) {
          let imgToCompress = files.filter(f => f.type.includes("image")); // bruhh
          let aFiles = files.filter(f => !f.type.includes("image")) // bruhh
          if (imgToCompress.length === 0) {
            resolve(aFiles);
            return;
          }
          let processedFiles = []
          imgToCompress.forEach(file => {
            let reader = new FileReader();
            reader.onloadend = () => {
              compressImage(reader.result, 'blob').then(fileBlob => {
                let fNameArr = file.name.split('.')
                fNameArr[fNameArr.length-1]='jpg'

                processedFiles.push(new File([fileBlob], fNameArr.join('.'), {
                  lastModified: file.lastModified,
                  type: 'image/jpeg'
                }));
                if (processedFiles.length === imgToCompress.length) {
                  resolve([...processedFiles, ...aFiles])
                }
              });
            }
            reader.onerror = (e) => {
              console.log("err", e);
            };
            reader.readAsDataURL(file)
          });
        } else {
          resolve(files);
        }
      });

      let pFiles = await filesHandler();
      console.log(pFiles)
      if (pFiles.length > 0) {
        pFiles.forEach(file => {
          console.log("File ", file.name, "has size: ", file.size)
          formData.append("files", file)
        });
      }

      let res = await axios.put(apiEndpoint.uploadFiles, formData, {
        headers: {
          "Content-Type": "multipart/formdata"
        }
      });
      if (res.status !== 200) {
        ctx.commit("showError", res.data)
        return false
      }
      return res.data;
    }
  },
  modules: {
    user,
    weather,
    alarmSchedule,
    pageList
  }
})
